.advertise-header {
  margin: 0 auto 70px auto;
  text-align: center;
  width: 610px;

  h1 {
    border-bottom: 2px solid #d1d2d4;
    color: #3e424f;
    display: inline-block;
    font-size: 54px;
    line-height: 98px;
    padding: 0 28px;
    margin-top: 50px;
    margin-bottom: 0;
  }

  h2 {
    color: #62697d;
    font-size: 18px;
    font-weight: normal;
    line-height: 26px;
    margin: 25px 0 30px 0;
  }

  a {
    background: var(--link-blue);
    color: #fff;
    display: inline-block;
    font-size: 18px;
    line-height: 54px;
    padding: 0 32px;
    &:hover,
    &:active,
    &:focus {
      background: var(--link-blue-hover);
    }
  }
}

.advertise-statistics {
  background: #f3f5f8
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACAQMAAACjTyRkAAAABlBMVEXs7vHh4+b5xTe/AAAADElEQVQI12NoYGAAAAGEAIEo3XrXAAAAAElFTkSuQmCC')
    repeat-x;
  border-bottom: 2px solid #d6dae1;
  color: #707990;
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;

  .advertise-statistics_box {
    background: #fff;
    border-bottom: 4px solid var(--link-blue);
    color: #62697d;
    height: 216px;
    margin: 10px 10px 30px 10px;
    padding-top: 110px;
    float: left;
    width: 300px;
    b {
      color: var(--link-blue);
      display: block;
      font-size: 32px;
    }
  }

  p {
    margin: 15px 0 40px 0;
    font-size: 14px;
  }

  a {
    color: var(--link-blue);
    display: inline-block;
    &:hover,
    &:active,
    &:focus {
      color: var(--link-blue-hover);
    }
  }

  small {
    color: #c1c2c5;
    font-size: 12px;
    a {
      color: #c1c2c5;
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
