.tooltip_hdr {
  background: #494f5e;
  border-left: 1px solid #494f5e;
  border-right: 1px solid #494f5e;
  border-top: 1px solid #494f5e;
  padding: 6px;
  font-family: 'Lato', Verdana, Tahoma, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  overflow: hidden;
  z-index: 100000;
}

.tooltip_bdy {
  background: #494f5e;
  border-left: 1px solid #494f5e;
  border-right: 1px solid #494f5e;
  border-bottom: 1px solid #494f5e;
  padding: 0 6px 6px 6px;
  color: #fff;
  overflow: hidden;
  z-index: 100000;
}

.tooltip_tab {
  background: #494f5e;
  font-family: 'Lato', Verdana, Tahoma, sans-serif;
  font-size: 12px;
  color: #fff;
  overflow: hidden;
}

.tooltip_short_hdr {
  visibility: hidden;
  overflow: hidden;
}

.tooltip_short_bdy {
  background: #494f5e;
  border: 1px solid #494f5e;
  padding: 6px;
  font-family: 'Lato', Verdana, Tahoma, sans-serif;
  font-size: 12px;
  color: #fff;
  overflow: hidden;
}

.tabchrtbdy {
  /*background: url('/gfx/chart_hover_bg.gif') repeat-x;*/
  background: #494f5e;
  border: 1px solid #494f5e;
  font-family: 'Lato', Verdana, Tahoma, sans-serif;
  font-size: 12px;
  color: #fff;
  overflow: hidden;
  img {
    display: block;
  }
}

.tabchrtbdy-ta {
  /*background: url('/gfx/chart_hover_bg_ta.gif') repeat-x;*/
  background: #494f5e;
  border: 1px solid #494f5e;
  font-family: 'Lato', Verdana, Tahoma, sans-serif;
  font-size: 12px;
  color: #fff;
  overflow: hidden;
}

.hoverchart {
  position: relative;
  background: #fff;
  border: 1px solid #000000;
  font-family: Arial, Verdana, Tahoma;
  font-size: 11px;
  color: #000000;
  overflow: hidden;
  line-height: 13px;

  &:before {
    content: 'Loading Chart…';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #494f5e;
    opacity: 0;
    font-size: 12px;
    font-weight: bold;
    pointer-events: none;
    animation: opacity 0s linear 250ms forwards;
  }

  img {
    position: relative;
    display: block;
  }

  div {
    background: url('/gfx/chart_hover_bg2.gif') 0px -180px repeat-x;
    padding: 5px;
  }

  b {
    display: block;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
