body.is-index {
  @media (max-width: 1024px) {
    body.is-index.is-elite .header-container {
      width: auto;
      margin: 0 5px;
    }
  }

  .hp_chart {
    .left {
      display: none;
    }
  }

  .t-home-table {
    font-family: Verdana, Arial, Tahoma;
    font-size: 11px;
  }

  tr.t-home-table-top {
    background-color: #e0e0e0;

    th {
      font-weight: 400;
    }
  }
  .t-home-table td,
  .t-home-table th {
    padding: 0 1px;
  }
  .t-home-table th {
    font-weight: 400;
  }

  .nn-title {
    font-family: Verdana, Arial, Tahoma;
    font-size: 20px;
    color: #a3a3a3;
    font-weight: bold;
  }

  .nn-date {
    font-family: Tahoma, Verdana, Arial, Tahoma;
    font-size: 11px;
    color: #555555;
    text-decoration: none;
    white-space: nowrap;
    line-height: 18px;
  }

  a.nn-title-link:link {
    font-family: Verdana, Arial, Tahoma;
    font-size: 17px;
    color: #1e6dc0;
    text-decoration: none;
    font-weight: bold;
  }

  a.nn-title-link:visited {
    font-family: Verdana, Arial, Tahoma;
    font-size: 17px;
    color: #1e6dc0;
    text-decoration: none;
    font-weight: bold;
  }

  a.nn-title-link:hover {
    text-decoration: underline;
  }

  a.nn-tab-link:link {
    font-family: Verdana, Arial, Tahoma;
    font-size: 12px;
    color: #1e6dc0;
    text-decoration: none;
  }

  a.nn-tab-link:visited {
    font-family: Verdana, Arial, Tahoma;
    font-size: 12px;
    color: #a0a0a0;
    text-decoration: none;
  }

  a.nn-tab-link:hover {
  }

  tr.nn {
    background-color: #ffffff;
    background-image: url(/gfx/news/tr_news.gif);
    background-repeat: repeat-x;
    vertical-align: middle;
  }

  tr.nn-home {
    background-color: #ffffff;
    background-image: url(/gfx/news/tr_news_home.gif);
    background-repeat: repeat-x;
    vertical-align: middle;

    &:nth-child(2) {
      background-image: url(/gfx/news/tr_news_home_first.gif);
    }

    &:hover {
      background-color: #fffcce;
      background-image: url(/gfx/news/tr_news_h.gif);
      cursor: pointer;
    }

    td:first-of-type {
      width: 18px;

      img {
        @apply !mx-auto block;
      }
    }

    .news-link-container a.nn-title-link {
      @apply truncate;
    }
  }

  .insider {
    font-family: Verdana, Arial, Tahoma;
    font-size: 11px;
  }

  .insider td {
    padding: 0 1px;
  }

  tr.insider-header {
    background-color: #dadada;
  }

  /* INDEX SIGNALS */
  .signal-big-screen {
    display: inline;
  }

  .signal-small-screen {
    display: none;
  }

  @media only screen and (max-width: 1400px) {
    .signal-big-screen {
      display: none;
    }

    .signal-small-screen {
      display: inline;
    }
  }

  /* MARKET STATS */
  .market-stats {
    cursor: default;
    font-family: Verdana, Arial, Tahoma;
    font-size: 10px;
    padding-top: 5px;
    width: 180px;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  .market-stats_labels {
    text-align: center;
    position: relative;
    height: 14px;
  }

  .market-stats_labels_left {
    font-weight: normal;
    position: absolute;
    left: 2px;
    top: 0;
  }

  .market-stats_labels_left span {
    color: #008800;
  }

  .market-stats_labels_right {
    font-weight: normal;
    position: absolute;
    right: 2px;
    top: 0;
  }

  .market-stats_labels_right span {
    color: #aa0000;
  }

  .market-stats_bar {
    background: #838383;
    background: linear-gradient(to bottom, #838383 0%, #838383 50%, #646464 51%, #646464 100%);
    position: relative;
    height: 10px;
  }

  .market-stats_bar_left-bar {
    background: #83c983;
    background: linear-gradient(to bottom, #83c983 0%, #83c983 50%, #64b464 51%, #64b464 100%);
    position: absolute;
    top: 0;
    left: 0;
    height: 10px;
  }

  .market-stats_bar_right-bar {
    background: #e69898;
    background: linear-gradient(to bottom, #e69898 0%, #e69898 50%, #dc7878 51%, #dc7878 100%);
    position: absolute;
    top: 0;
    right: 0;
    height: 10px;
  }

  .market-stats_bar_left-percent,
  .market-stats_bar_right-percent {
    color: #fff;
    position: absolute;
    top: 0;
    font-size: 10px;
    line-height: 9px;
    text-shadow: 0.5px 0.9px 0 rgba(0, 0, 0, 0.25);
  }

  .market-stats_bar_left-percent {
    left: 2px;
  }

  .market-stats_bar_right-percent {
    right: 2px;
  }

  /* MARKET SENTIMENT */
  #msb_bull,
  #msb_bear {
    border: none;
    color: #fff;
    display: block;
    width: 61px;
    height: 14px;
    line-height: 12px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.35);
    text-transform: uppercase;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 10px;
    font-family: Verdana, Arial;
  }

  #msb_bull {
    background: #83c983;
    background: linear-gradient(to bottom, #83c983 0%, #83c983 50%, #64b464 51%, #64b464 100%);
  }

  #msb_bear {
    background: #e69898;
    background: linear-gradient(to bottom, #e69898 0%, #e69898 50%, #dc7878 51%, #dc7878 100%);
  }

  #msb_bull:disabled,
  #msb_bear:disabled {
    cursor: default;
    opacity: 0.6;
  }

  .gt-button {
    @apply inline-flex items-center justify-center rounded-md border-none font-sans outline-none ring-gray-200 font-medium focus-visible:ring-2 dark:ring-gray-500;
    /* button gray */
    @apply bg-gray-200/30 text-gray-900 hover:bg-gray-200/50 dark:bg-gray-500/30 dark:text-gray-50 dark:hover:bg-gray-500/50;
    /* button default */
    @apply h-7 min-w-7 cursor-pointer px-2 text-2xs disabled:cursor-not-allowed;

    &.is-medium {
      @apply h-8 min-w-8 px-2 text-sm;
    }

    &.is-primary {
      @apply bg-blue-400 text-white hover:bg-blue-500;
    }
  }

  .tabchrtbdy {
    background: url('/gfx/chart_hover_bg2.gif') repeat-x;
    border: 1px solid #000000;
    font-family: Arial, Verdana, Tahoma;
    font-size: 11px;
    color: #000000;
    overflow: hidden;
    line-height: 13px;
    img {
      display: block;
    }
  }

  .tabchrtbdy-ta {
    background: url('/gfx/chart_hover_bg_ta.gif') repeat-x;
    border: 1px solid #000000;
    font-family: Arial, Verdana, Tahoma;
    font-size: 11px;
    color: #000000;
    overflow: hidden;
    line-height: 13px;
    img {
      display: block;
    }
  }

  .tooltip_hdr {
    background: #ffffe1;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-top: 1px solid #000000;
    padding: 2px;
    font-family: Arial, Verdana, Tahoma;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    overflow: hidden;
    line-height: 13px;
  }

  .tooltip_bdy {
    background: #ffffe1;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    padding: 2px;
    color: #000000;
    overflow: hidden;
    line-height: 13px;
  }

  .tooltip_tab {
    background: #ffffe1;
    font-family: Arial, Verdana, Tahoma;
    font-size: 12px;
    color: #000000;
    overflow: hidden;
  }

  .tooltip_short_hdr {
    visibility: hidden;
    overflow: hidden;
  }

  .tooltip_short_bdy {
    background: #ffffe1;
    border: 1px solid #000000;
    padding: 2px;
    font-family: Arial, Verdana, Tahoma;
    font-size: 12px;
    color: #000000;
    overflow: hidden;
  }

  .nn_tooltip_hdr {
    overflow: hidden;
    visibility: hidden;
  }

  .nn_tooltip_bdy {
    background: #ffffe1;
    border: 1px solid #000000;
    padding: 2px;
    font-family: Arial, Verdana, Tahoma;
    font-size: 11px;
    color: #000000;
    overflow: hidden;
  }

  .news_source_icon {
    height: 18px;
  }

  #rectangle_position {
    background-color: #f0f0f0;
  }

  .positive-50 {
    color: #008800;
  }
  .positive-100 {
    color: #008800;
    background: #e7ffe2;
  }
  .positive-200 {
    color: #008800;
    background: #e7ffe2;
    font-weight: bold;
  }

  .negative-50 {
    color: #aa0000;
  }
  .negative-100 {
    color: #aa0000;
    background: #ffeeee;
  }
  .negative-200 {
    color: #aa0000;
    background: #ffeeee;
    font-weight: bold;
  }
}
