.forex {
  @media (max-width: 1240px) {
    .chart-menu_charts .chart-menu_chart-preview {
      margin: 0 2px;
      width: 83px;
      overflow: hidden;
      svg {
        float: right;
      }
    }
  }

  .tile {
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    line-height: 15px;
    float: left;
    margin: 1px;
    width: 156px;
    height: 140px;
    position: relative;

    &.is-crypto {
      .tile_last-price {
        font-size: 22px;
        top: 22px;
      }

      .tile_change {
        font-size: 14px;
      }
    }

    .tile_label {
      font-weight: bold;
      text-transform: uppercase;
      position: absolute;
      top: 9px;
      left: 9px;
    }

    .tile_last-price {
      font-weight: bold;
      font-size: 30px;
      line-height: 30px;
      position: absolute;
      left: 8px;
      top: 32px;
      padding: 0;
    }

    .tile_change {
      position: absolute;
      right: 7px;
      top: 9px;
    }

    .tile_high-low {
      color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 30px;
      right: 7px;
      text-align: right;
      line-height: 16px;

      div {
        padding: 0 2px;
        margin-right: -2px;
        line-height: 15px;
        margin-bottom: 1px;
      }
    }

    .tile_dot {
      opacity: 0.5;
      font-family: Verdana, sans-serif;
      position: relative;
      top: -2px;
      font-size: 12px;
    }

    .tile_high-label,
    .tile_low-label {
      font-family: Verdana, sans-serif;
      font-size: 13px;
      display: block;
      float: left;
      margin-right: 3px;
    }

    .tile_low-label {
      padding-right: 1px;
    }

    .tile_content {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.09) 0%, transparent 100%);
      height: 140px;
      margin: 0;
      padding: 0;

      svg {
        position: absolute;
        bottom: 0;
        left: 0;

        text {
          fill: rgba(255, 255, 255, 0.5);
          font-size: 7px;
          font-weight: bold;
          text-anchor: middle;
        }
      }

      .area {
        fill: rgba(255, 255, 255, 0.2);
        stroke: rgba(255, 255, 255, 0.65);
        stroke-width: 1.1px;
      }

      .line {
        stroke: #fff;
        fill: none;
        stroke-width: 1.1px;
      }
    }
  }

  .vertical-bar-chart {
    margin: 5px;
    h1 {
      color: #4c5261;
      font-size: 11px;
      margin: 10px 0;
      text-align: center;
      text-transform: uppercase;
    }
    .rect {
      cursor: default;
      transition: all 0.5s ease-in-out;
      .value {
        position: absolute;
        text-align: center;
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        width: 100%;
      }
      .label {
        color: #fff;
        font-size: 11px;
        font-weight: bold;
        position: absolute;
        text-align: center;
        width: 100%;
        height: 20px;
        line-height: 20px;
      }
    }
    .rect:hover {
      cursor: default;
      &.is-clickable {
        cursor: pointer;
      }
    }
    .bar-chart_tick {
      border-bottom: 1px dashed #cbced5;
      height: 1px;
      width: 100%;
      div {
        width: 30px;
        height: 20px;
        line-height: 20px;
        text-align: left;
        margin: 0;
        font-size: 11px;
        position: absolute;
        top: -10px;
        color: #4c5261;
        opacity: 0.5;
        right: -34px;
      }
    }
  }
}

/* TODO move */
a.tab-link:link {
  color: #1e6dc0;
  text-decoration: none;
}
a.tab-link:visited {
  color: #1e6dc0;
  text-decoration: none;
}
a.tab-link:hover {
  color: #1e6dc0;
  text-decoration: underline;
}
a.tab-link.active {
  font-weight: bold;
}
a.tab-link.is-selected {
  color: #000;
}

a.tab-link-nw:link {
  color: #1e6dc0;
  text-decoration: none;
  white-space: nowrap;
}
a.tab-link-nw:visited {
  color: #1e6dc0;
  text-decoration: none;
  white-space: nowrap;
}
a.tab-link-nw:hover {
  color: #1e6dc0;
  text-decoration: underline;
  white-space: nowrap;
}

a.tab-link-u:link {
  color: #1e6dc0;
  text-decoration: underline;
}
a.tab-link-u:visited {
  color: #1e6dc0;
  text-decoration: underline;
}
a.tab-link-u:hover {
  color: #34a2da;
  text-decoration: underline;
}

a.tab-link-news:link {
  color: #1e6dc0;
  text-decoration: none;
}
a.tab-link-news:visited {
  color: #1e6dc0;
  text-decoration: none;
}
a.tab-link-news:hover {
  color: #1e6dc0;
  background-color: #e0f0ff;
  text-decoration: none;
}

a.tab-link-hl:link {
  color: #1e6dc0;
  text-decoration: none;
  background-color: #fff866;
  padding: 0px 4px 0px 4px;
}
a.tab-link-hl:visited {
  color: #1e6dc0;
  text-decoration: none;
  background-color: #fff866;
  padding: 0px 4px 0px 4px;
}
a.tab-link-hl:hover {
  color: #1e6dc0;
  text-decoration: underline;
  background-color: #fff866;
  padding: 0px 4px 0px 4px;
}

.body-table {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  padding: 2px 3px;

  td {
    padding: 0;
  }
}

.body-table-nw {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  padding: 2px;
}

.body-table-news {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #1e6dc0;
  text-decoration: none;
  background: #f9f9ff;
}

.body-table-news-gain {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #009900;
  text-decoration: none;
  background: #f0fff0;
  white-space: nowrap;
}

.body-table-news-loss {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #dd3333;
  text-decoration: none;
  background: #fff0f0;
  white-space: nowrap;
}

/* stock news badges */
.nn .fv-label {
  @apply rounded-none font-sans text-3xs;

  &.is-neutral {
    @apply bg-[#E0E0E0] text-[#525252];
  }

  &.is-negative-50 {
    @apply bg-[#FFEEEE] text-[#DD1414];
  }

  &.is-negative-100 {
    @apply bg-[#FFD7D7] text-[#AA0000];
  }

  &.is-negative-200 {
    @apply bg-[#FFB2B2] text-[#790000];
  }

  &.is-positive-50 {
    @apply bg-[#E7FFE2] text-[#008800];
  }

  &.is-positive-100 {
    @apply bg-[#9EF49E] text-[#006000];
  }

  &.is-positive-200 {
    @apply bg-[#6CDE6C] text-[#004400];
  }

  &.is-opaque {
    @apply border border-white bg-transparent px-0.5;

    &.is-neutral {
      @apply bg-gray-50 text-gray-600;
    }

    &.is-negative-50 {
      @apply bg-red-50;
    }

    &.is-negative-100 {
      @apply bg-red-100;
    }

    &.is-negative-200 {
      @apply bg-red-200 text-red-700;
    }

    &.is-positive-50 {
      @apply bg-green-50;
    }

    &.is-positive-100 {
      @apply bg-green-100 text-green-500;
    }

    &.is-positive-200 {
      @apply bg-green-200;
    }
  }
}

.nn:hover .fv-label {
  @apply border-white;

  &:hover {
    @apply border-current;
  }
}

.body-table-rating-downgrade {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #dd3333;
  text-decoration: none;
  background: #fff0f0;
  background: url('/gfx/rating/tr_d.gif') repeat-x;
}

.body-table-rating-upgrade {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #009900;
  text-decoration: none;
  background: #f0fff0;
  background: url('/gfx/rating/tr_u.gif') repeat-x;
}

.body-table-rating-neutral {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #333333;
  text-decoration: none;
  background: #f0f0f0;
  background: url('/gfx/rating/tr_n.gif') repeat-x;
}

.body-table-profile {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  background: #f9f9f9;
}

.body-table-top {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.count-text {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #888888;
  text-decoration: none;
}

.pages-combo {
  font-family: Verdana, Arial, Tahoma;
  font-size: 10px;
  color: #000000;
  text-decoration: none;
  background-color: #efefef;
}

.table-light {
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 1px;
  background-color: #d3d3d3;

  tr {
    background-color: #ffffff;

    &:hover {
      background-color: #e0f0ff;
    }

    &:nth-child(even) {
      background-color: #f4f4f4;

      &:hover {
        background-color: #e0f0ff;
      }
    }
  }

  .table-top {
    padding: 2px;
  }
}

th.table-top,
td.table-top {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
  background-color: #d3d3d3;

  &.cursor-pointer:hover,
  &.is-selected:hover {
    background-color: #c3c3c3;
  }

  &.is-selected {
    background-color: #e3e3e3;
  }

  &.is-white {
    background-color: #ffffff;
  }
}

tr.table-light-row {
  background-color: #ffffff;
}
tr.table-light-row-h {
  background-color: #e0f0ff;
}
tr.table-light2-row {
  background-color: #f4f4f4;
}
tr.table-light3-row {
  background-color: #ffffff;
}

tr.table-light-row-cp {
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #e0f0ff;
  }
}

tr.table-dark-row {
  background-color: #f4f4f4;
}
tr.table-dark-row-h {
  background-color: #e0f0ff;
}

tr.table-dark-row-cp {
  background-color: #f4f4f4;
  cursor: pointer;
}
tr.table-dark-row-cp-h {
  background-color: #e0f0ff;
  cursor: pointer;
}

td.table-light-cell-cp {
  background-color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: #e0f0ff;
  }
}

td.table-dark-cell-cp {
  background-color: #f4f4f4;
  cursor: pointer;
}

#forex_charts,
#futures_charts,
#crypto_charts {
  img {
    display: inline-block;
    border: 3px solid #fff;
    box-sizing: content-box;

    &:hover {
      border-color: #b0d0f0;
    }
  }
}

@media (max-width: 1194px) {
  #forex_charts,
  #futures_charts,
  #crypto_charts {
    .tools {
      border: 1px solid #f3f3f3;
      height: 41px;
      left: 0;
      position: relative;
      width: 100%;
      top: 0;
    }

    .tools .icon {
      width: calc(100% / 18);
      height: 39px;
      text-align: center;
    }

    .tools .icon:hover,
    .tools .icon.active {
      width: calc(100% / 18);
    }
  }
}
