.news {
  margin-top: 20px;
  position: relative;

  .news_favorite-blog {
    font-size: 11px !important;
    right: 0;
    position: absolute;
    top: -13px;
  }

  .nn-title {
    font-family: Verdana, Arial, Tahoma;
    font-size: 20px;
    color: #a3a3a3;
    font-weight: bold;
    text-transform: uppercase;
  }

  .nn-date {
    font-family: Tahoma, Verdana, Arial, Tahoma;
    font-size: 11px;
    color: #555555;
    text-decoration: none;
    white-space: nowrap;
    line-height: 13px;
  }

  a.nn-title-link:link {
    font-family: Verdana, Arial, Tahoma;
    font-size: 17px;
    color: #1e6dc0;
    text-decoration: none;
    font-weight: bold;
  }

  a.nn-title-link:visited {
    font-family: Verdana, Arial, Tahoma;
    font-size: 17px;
    color: #1e6dc0;
    text-decoration: none;
    font-weight: bold;
  }

  a.nn-title-link:hover {
    text-decoration: underline;
  }

  a.nn-tab-link:link {
    display: inline;
    font-family: Verdana, Arial, Tahoma;
    font-size: 12px;
    color: #1e6dc0;
    text-decoration: none;
  }

  .news-badges-container {
    position: relative;
    top: -1px;
  }

  .stock-news-label {
    display: inline-block;
    height: 14px;
    line-height: 13px;

    span {
      position: relative;
      top: -0.5px;
    }
  }

  a.nn-tab-link:visited {
    font-family: Verdana, Arial, Tahoma;
    font-size: 12px;
    color: #a0a0a0;
    text-decoration: none;
  }

  tr.nn-home {
    visibility: visible !important;

    td {
      min-height: 20px;

      &:first-of-type {
        width: 20px;
      }

      &:nth-of-type(2) {
        width: 60px;
      }

      &.nn-date {
        line-height: 20px;
      }
    }
  }

  tr.nn-home,
  tr.nn {
    background-color: #ffffff;
    background-image: url(/gfx/news/tr_news.gif);
    background-repeat: repeat-x;
    vertical-align: middle;

    &:hover {
      background-color: #fffcce;
      background-image: url(/gfx/news/tr_news_h.gif);
      cursor: pointer;
    }

    td {
      padding: 2px;
      line-height: 15px;

      &:first-child {
        img {
          @apply !mx-auto block;
        }
      }
    }

    .news-link-container a.nn-tab-link {
      @apply truncate;
    }
  }

  .portfolio-combo {
    font-family: Verdana, Arial, Tahoma;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    background-color: #f4f4f4;
    border: 1px solid gray;
  }

  .portfolio-combo-h {
    font-family: Verdana, Arial, Tahoma;
    font-size: 12px;
    color: #000000;
    text-decoration: none;
    background: #fff466;
  }
}

.news_menu {
  background: #ebebeb;
  border-bottom: 1px solid #d9d9d9;
  height: 31px;
  margin-bottom: 2px;

  .menu-pages {
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      display: inline-block;
      a {
        color: #5faaf4;
        display: block;
        font-weight: bold;
        font-size: 11px;
        line-height: 30px;
        padding: 0 5px 1px 5px;
        text-transform: uppercase;
        &:hover,
        &:active,
        &:focus {
          color: #3e424f;
        }
      }
      &.is-active {
        a {
          color: #3e424f;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAfCAIAAACpqzA1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABtJREFUeNpifv36DRMDAwM9MPPbL+zIfIAAAwB8oATrQEGXqQAAAABJRU5ErkJggg==')
            repeat-x;
        }
      }
    }
  }
}

.news_tooltip-bdy {
  background: #ffffe1;
  border: 1px solid #000000;
  padding: 2px;
  color: #000000;
  overflow: hidden;
}

.news_tooltip-tab {
  background: #ffffe1;
  font-family: Arial, Verdana, Tahoma;
  font-size: 12px;
  color: #000000;
  overflow: hidden;
}

.news_source_icon {
  background: no-repeat center center;
  background-size: 16px 16px;
  height: 20px;

  &.is-left {
    background-position: left center;
  }

  &.is-1 {
    background-image: url('/gfx/news/logo/1.png');
  }

  &.is-2 {
    background-image: url('/gfx/news/logo/2.png');
  }

  &.is-3 {
    background-image: url('/gfx/news/logo/3.gif');
  }

  &.is-4 {
    background-image: url('/gfx/news/logo/4.png');
  }

  &.is-5 {
    background-image: url('/gfx/news/logo/5.gif');
  }

  &.is-6 {
    background-image: url('/gfx/news/logo/6.png');
  }

  &.is-7 {
    background-image: url('/gfx/news/logo/7.png');
  }

  &.is-8 {
    background-image: url('/gfx/news/logo/8.png');
  }

  &.is-9 {
    background-image: url('/gfx/news/logo/9.png');
  }

  &.is-10 {
    background-image: url('/gfx/news/logo/10.png');
  }

  &.is-11 {
    background-image: url('/gfx/news/logo/fox.png');
  }

  &.is-102 {
    background-image: url('/gfx/news/logo/mishtalk.png');
  }

  &.is-105 {
    background-image: url('/gfx/news/logo/traderfeed.png');
  }

  &.is-113 {
    background-image: url('/gfx/news/logo/howard-lindzon.png');
  }

  &.is-114 {
    background-image: url('/gfx/news/logo/seeking-alpha.png');
  }

  &.is-121 {
    background-image: url('/gfx/news/logo/tdi.png');
  }

  &.is-123 {
    background-image: url('/gfx/news/logo/fallon.png');
  }

  &.is-132 {
    background-image: url('/gfx/news/logo/132.png');
  }

  &.is-133 {
    background-image: url('/gfx/news/logo/marketfolly.png');
  }

  &.is-136 {
    background-image: url('/gfx/news/logo/dailyreckoning.png');
  }

  &.is-137 {
    background-image: url('/gfx/news/logo/vantage.png');
  }

  &.is-140 {
    background-image: url('/gfx/news/logo/marketrealist.jpg');
  }

  &.is-141 {
    background-image: url('/gfx/news/logo/ar.png');
  }

  &.is-142 {
    background-image: url('/gfx/news/logo/142.gif');
  }
  &.is-143 {
    background-image: url('/gfx/news/logo/big-picture.png');
  }
  &.is-144 {
    background-image: url('/gfx/news/logo/capital-spectator.png');
  }
  &.is-145 {
    background-image: url('/gfx/news/logo/angry-bear.png');
  }
  &.is-146 {
    background-image: url('/gfx/news/logo/stratechery.png');
  }
  &.is-147 {
    background-image: url('/gfx/news/logo/ria.png');
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.25), only screen and (min-device-pixel-ratio: 1.25) {
  .news_source_icon.is-5 {
    background-image: url('/gfx/news/logo/5.svg');
  }

  .news_source_icon.is-3 {
    background-image: url('/gfx/news/logo/3.svg');
  }
}
