.financials-table {
  tbody td {
    @apply border-primary first:border-l last:border-r;
  }

  .is-heatmap {
    @apply border-t-[theme(backgroundColor.primary)];

    &:not(:first-of-type)::before {
      @apply absolute bottom-0 left-0 top-0 z-0 w-px bg-[theme(backgroundColor.primary)] content-[''];
    }
  }

  tbody:first-of-type .is-heatmap {
    @apply border-t-primary;
  }

  .hoverable-section {
    @apply cursor-pointer;

    &:hover {
      > td,
      tr td {
        @apply border-accent bg-accent;
      }

      /** Simple tables where rows are TR and not TBody have both classes on the same element */
      & + .hoverable-section .is-top-border,
      & + .hoverable-section.is-top-border {
        td {
          @apply border-t-accent;
        }
      }

      .is-heatmap:before {
        @apply content-none;
      }
    }
  }

  .is-top-border td {
    @apply border-t;
  }
  .is-bottom-border td {
    @apply border-b;
  }
  .is-top-radius td {
    @apply first:rounded-tl-md last:rounded-tr-md;
  }
  .is-bottom-radius td {
    @apply first:rounded-bl-md last:rounded-br-md;
  }
}
