@import './map-hover.css';

.content.map {
  --sidebar-width: 200px;
  display: flex;
  flex-direction: column;

  .fv-container {
    padding: 0;

    &:fullscreen {
      background: #262931;

      #body {
        margin-right: 5px;
      }
    }
  }

  .map-container {
    display: flex;
    height: 100%;
  }

  .sidebar {
    width: var(--sidebar-width);

    a {
      color: #5faaf4;
      font-size: 11px;

      &:hover,
      &:active,
      &:focus {
        color: #fff;
      }

      &.is-switch-axis {
        background: url('/img/maps/icons.png') -73px 0;
        display: block;
        width: 11px;
        height: 51px;
        position: absolute;
        top: -18px;
        right: -13px;

        @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
          background-image: url('/img/maps/icons_2x.png');
          background-size: 100px 51px;
        }

        &:hover {
          background-position: -89px 0;
        }
      }
    }
  }

  #map {
    @apply font-bold;
    flex: 1;
    overflow: hidden;

    .chart {
      display: block;
    }

    .hover-canvas {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    .chart svg {
      cursor: default;
    }

    #body {
      padding-bottom: 15px;
    }

    #canvas-wrapper {
      position: relative;
      margin: 0 auto;
    }

    .slider canvas {
      display: inline-block;
      margin-top: -1px;
    }

    .stocks {
      @apply font-bold;
      cursor: pointer;
      fill: #fff;
      font-size: 12px;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 1);

      .active {
        stroke: #ffd614;
        stroke-width: 2px;
      }
    }

    .sectors rect {
      fill: #262931;
    }

    .sectors text {
      fill: rgb(112, 121, 144);
      font-size: 12px;
      font-weight: 900;
      text-shadow: none; /*1px 1px 0px rgba(255, 255, 255, 1);*/
      text-transform: uppercase;
    }

    .industries text {
      fill: #f2f3f4;
      font-size: 11px;
      text-shadow: none; /*1px 1px 0px rgba(255, 255, 255, 1);*/
      text-transform: uppercase;
    }

    .name,
    .perf {
      dominant-baseline: central;
      text-anchor: middle;
    }

    .info {
      background: url('/img/maps/icon_help.png') no-repeat 0 6px;
      color: #707990;
      font-size: 11px;
      padding-left: 20px;
      margin-left: 5px;
      line-height: 13px;

      @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        background-image: url('/img/maps/icon_help_2x.png');
        background-size: 12px 12px;
      }
    }

    .scale {
      color: #fff;
      display: inline-block;
      font-size: 11px;
      font-weight: normal;
      line-height: 24px;
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
      margin-top: 15px;
      flex-shrink: 0;

      div {
        cursor: default;
        display: inline-block;
        float: left;
        text-align: center;
        margin-left: 1px;
      }
    }
  }

  .bubbles-container {
    display: flex;

    #map {
      overflow: visible;
    }
  }

  .icon {
    background: url('/img/maps/icons.png') no-repeat;
    display: inline-block;
    position: relative;
    cursor: pointer;
    top: 1px;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('/img/maps/icons_2x.png');
      background-size: 100px 51px;
    }

    &.is-map {
      width: 11px;
      height: 12px;
      padding-right: 16px;
    }

    &.is-bubbles {
      background-position: -17px 0;
      width: 15px;
      height: 12px;
      padding-right: 20px;
    }

    &.is-presets {
      background-position: -37px 0;
      width: 11px;
      height: 12px;
      padding-right: 15px;
    }

    &.is-export {
      background-position: -54px 0;
      width: 13px;
      height: 12px;
      padding-right: 18px;
    }
  }

  a:hover .icon,
  a.is-active .icon {
    &.is-map {
      background-position: 0 -14px;
    }
    &.is-bubbles {
      background-position: -17px -14px;
    }
    &.is-presets {
      background-position: -37px -14px;
    }
    &.is-export {
      background-position: -54px -14px;
    }
  }
}

#bubble-chart canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html.is-map,
body.has-map {
  background: #262931 !important; /* HOTFIX */

  &.dark :root {
    --bg-primary: 0 0 0;
  }
}

body.has-map,
body.dark {
  .footer a {
    color: #5fa4f4;
  }

  .footer_dot {
    color: #707990;
  }
}

div.tooltips {
  cursor: pointer;
  display: inline;
  position: absolute;
  &.is-horizontal {
    background: url('/img/bubbles/handle_horizontal.png');
    width: 9px;
    height: 17px;
    margin-top: 3px;
    margin-left: -5px;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('/img/bubbles/handle_horizontal_2x.png');
      background-size: 9px 17px;
    }
  }
  &.is-vertical {
    background: url('/img/bubbles/handle_vertical.png');
    width: 17px;
    height: 9px;
    margin-top: -3px;
    margin-left: -4px;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('/img/bubbles/handle_vertical_2x.png');
      background-size: 17px 9px;
    }
  }
}
div.tooltips div {
  position: absolute;
  width: 60px;
  color: #ffffff;
  background: #4c5261;
  font-size: 10px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  /* visibility: hidden; */
  display: none;
  border-radius: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}
div.tooltips div:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid #4c5261;
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
div.tooltips div.is-shown {
  /* visibility: visible; */
  display: block;
  opacity: 1;
  bottom: 28px;
  left: 50%;
  margin-left: -31px;
  z-index: 999;
}

body.is-map-expanded {
  /* Hide navigation */
  table.header,
  table.navbar,
  & > div.footer,
  .map-view-switch,
  .map-sidebar,
  .screener_filter-tabs-section,
  .screener-groups_settings-table-wrapper {
    display: none;
  }

  /* Make map full width */
  .fv-container {
    min-width: 720px;
    max-width: 100%;
  }

  /* Add space around map */
  .content #map {
    padding: 0;
    margin: 10px;
  }

  /* Remove additional bottom padding in expanded mode for elite */
  &.is-elite .content #map #body {
    padding: 0;
  }
}

body.is-screener.is-map-expanded {
  #filter-table-top,
  #filter-table-tabs,
  #filter-table-filters,
  #screener-views-table,
  .footer {
    display: none;
  }

  .fv-container {
    width: 100%;
    min-width: 720px;
  }

  #root {
    min-width: 100%;
    min-height: 100%;
  }
}

@media (max-width: 1024px) {
  body:not(.is-map-expanded) .content.map a.is-fullscreen {
    display: none;
  }
}

@media (max-width: 1430px) {
  body:not(.is-map-expanded):not(.is-screener) .content.map #map {
    margin-right: 10px;
  }
}

@media (min-width: 1500px) {
  .content.map .fv-container {
    width: 95%;
    max-width: 1615px;
  }
}
