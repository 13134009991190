.quote-header-wrapper {
  /**
   * Our body element has `display: table` to fix problems with background on smaller resolutions.
   * This has a side effect of breaking dynamic width with ellipsis (the container always fills full width)
   * In order to get around this issue, we need to position the header content absolutely so that it isn’t affected.
   */
  @apply relative h-[4.25rem];
}

.quote-header {
  /** keep for legacy design */
  --font-family: theme(fontFamily.sans);
  @apply !font-sans;
  @apply absolute top-0 flex w-full items-end justify-between space-x-2;
}

.quote-header_left,
.quote-price_wrapper {
  @apply -mb-1;
}

.quote-header_ticker-wrapper,
.quote-price_wrapper {
  @apply -mt-0.5;
}

.quote-header_left {
  @apply flex h-full flex-col items-start overflow-hidden;
}

.quote-header_ticker-wrapper {
  @apply flex max-w-full items-baseline space-x-1.5;
}

.quote-header_ticker-wrapper_ticker {
  @apply m-0 text-3xl leading-none text-muted font-bold;
}

.quote-header_ticker-wrapper_company {
  @apply m-0 -mb-1 overflow-hidden truncate text-base text-muted-2 font-bold;
}

.quote-header_right {
  @apply flex h-11 shrink-0 items-end space-x-4;
}

.quote-links {
  /** keep for legacy design */
  @apply !font-sans;
  @apply absolute bottom-0 mt-1 flex w-full justify-between text-2xs;
}

/** Price component **/
.quote-price {
  @apply flex flex-col items-end tabular-nums;
}

.quote-price_date {
  @apply text-3xs text-muted-2 font-medium;
}

.quote-price_wrapper {
  @apply flex items-center space-x-1;
}

.quote-price_wrapper_price {
  @apply block text-3xl leading-none tracking-[-0.03em] text-muted font-bold;
}

.quote-price_wrapper_change {
  @apply whitespace-nowrap text-right text-sm leading-none font-semibold;

  td {
    @apply p-0 text-right align-middle last:pl-px last:text-center;
  }
}
