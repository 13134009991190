.footer {
  color: #707990;
  font-size: 11px;
  padding-bottom: 20px;
  text-align: center;
  a {
    color: var(--link-blue);
    font-size: 12px;
    text-decoration: none;
    vertical-align: middle;
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }
}

.footer_dot {
  color: #c0c3cc;
  font-size: 8px;
  padding: 0 2px;
  vertical-align: middle;
}

.footer_links {
  margin-bottom: 4px;
}
