.modal-elite-ad {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.25);
  font-family: Lato, sans-serif;
  display: none;
}

.modal-elite-ad_content {
  background: #2e3447 url(/img/a/elite-reminder-bg.png) no-repeat;
  color: #fff;
  position: absolute;
  width: 640px;
  height: 400px;
  left: 50%;
  top: 50%;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  margin-left: -320px;
  margin-top: -200px;
  z-index: 100;
  text-align: center;
}

@media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
  .modal-elite-ad_content {
    background-image: url(/img/a/elite-reminder-bg_2x.png);
    background-size: 640px 400px;
  }
}

.modal-elite-ad_close {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.modal-elite-ad h2 {
  font-size: 30px;
  line-height: 30px;
  margin-top: 120px;
  margin-bottom: 0;
}

.modal-elite-ad p {
  font-size: 15px;
  line-height: 22px;
  margin: 25px auto 40px auto;
  padding: 0 90px;
}

.modal-elite_button {
  background-color: #4ea4f4;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  line-height: 64px;
  width: 280px;
  height: 64px;
  outline: 0;
  text-decoration: none;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.modal-elite_button:hover {
  background-color: #2c94f4;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
}
