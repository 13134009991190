.fullview-profile {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  background: #f9f9f9;
  border-width: 5px 5px 5px 5px;
  border-style: solid;
  border-color: #f9f9f9;
}

.fullview-title {
  font-family: Arial, Verdana, Tahoma;
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  margin-bottom: 3px;

  h1 {
    margin: 17px 0 0 0;
  }

  td {
    padding: 0;
  }
}

.fullview-ticker {
  font-family: Arial, Verdana, Tahoma;
  font-size: 14px;
  color: #1e6dc0;
  text-decoration: none;
  background-color: #ffffff;
}

.fullview-links {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #1e6dc0;
  text-decoration: none;
}

.fullview-links .active {
  font-weight: bold;
}

.fullview-ratings-outer {
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #d3d3c3;
}

td.fullview-ratings-inner {
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #d3d3c3;
}

.fullview-news-outer {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #1e6dc0;
  text-decoration: none;
  background: #f9f9ff;
  border-width: 0px 0px 0px 0px;
  border-style: solid;
  border-color: #d3d3c3;
  table-layout: fixed;
}

.snapshot-table2 {
  border-width: 0px 0px 1px 1px;
  border-style: solid;
  border-color: #d3d3d3;

  td {
    line-height: normal;
    padding: 2px;
    line-height: 1.4;
  }
}

.snapshot-table2.has-hovers tr.table-light-row:hover,
.snapshot-table2.has-hovers tr.table-dark-row:hover {
  background-color: #e0f0ff;
}

.snapshot-td2 {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d3d3d3;
  white-space: nowrap;
}

.snapshot-td2-cp {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  border-width: 1px 1px 0px 0px;
  border-style: solid;
  border-color: #d3d3d3;
  cursor: pointer;
  white-space: nowrap;
}

.news-link-container {
  display: flex;
  line-height: 15px;
}

.news-link-left {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.news-link-right {
  padding-left: 4px;
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
}

.news-link-right span {
  font-size: 9px;
}

.news-link-source {
  margin-right: 4px;
  font-size: 9px;
}

.news-link-source,
.news-link-right {
  color: #aa6dc0;
}

.quote-bar-chart {
  .grid path {
    stroke-width: 0;
  }

  .grid .tick line {
    stroke: rgba(70, 70, 70, 0.6);
    stroke-opacity: 0.3;
    stroke-dasharray: 1;
  }
}

.quote-bar-chart .bar-chart-title {
  font-size: 13px;
  fill: rgb(80, 80, 80);
}

.quote-bar-chart .bar--positive {
  fill: #83aac3;
}

.quote-bar-chart .bar--negative {
  fill: #c47676;
}

.quote-bar-chart .axis text {
  font: 10px Verdana;
  fill: rgb(70, 70, 70);
}

.quote-bar-chart .axis path,
.quote-bar-chart .axis line {
  fill: none;
  stroke: #dedede;
  shape-rendering: crispEdges;
}

.statements-table-header {
  font: 11px Verdana;
}

.statements-table .tooltip {
  position: relative;
}

.statements-table_tooltiptext {
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  text-align: center;
  padding: 5px 0;
}

.statements-table_tooltiptext.is-ad {
  padding: 0;
}

tr.insider-row.is-buy-1,
tr.insider-buy-row-1 {
  background-image: url(/gfx/insider/tr_b1.gif);
}

tr.insider-row.is-buy-2,
tr.insider-buy-row-2 {
  background-image: url(/gfx/insider/tr_b2.gif);
}

tr.insider-row.is-sale-1,
tr.insider-sale-row-1 {
  background-image: url(/gfx/insider/tr_s1.gif);
}

tr.insider-row.is-sale-2,
tr.insider-sale-row-2 {
  background-image: url(/gfx/insider/tr_s2.gif);
}

tr.insider-row.is-proposedSale-1,
tr.insider-proposedSale-row-1 {
  background-image: url(/gfx/insider/tr_ps1.gif);
}

tr.insider-row.is-proposedSale-2,
tr.insider-proposedSale-row-2 {
  background-image: url(/gfx/insider/tr_ps2.gif);
}

tr.insider-row,
tr.insider-option-row,
tr.insider-buy-row-1,
tr.insider-buy-row-2,
tr.insider-sale-row-1,
tr.insider-sale-row-2,
tr.insider-proposedSale-row-1,
tr.insider-proposedSale-row-2,
tr.insider-light-row-cp-h {
  background-color: #ffffff;
  background-repeat: repeat-x;

  &:hover {
    background: #fffac0;
  }
}

.yellow-tooltip {
  .tabchrtbdy {
    background: url('/gfx/chart_hover_bg2.gif') bottom left repeat-x;
    border: 1px solid #000000;
    font-family: Arial, Verdana, Tahoma;
    font-size: 11px;
    color: #000000;
    overflow: hidden;
    line-height: 13px;

    img {
      display: block;
    }
  }

  .tabchrtbdy-ta {
    background: url('/gfx/chart_hover_bg_ta.gif') repeat-x;
    border: 1px solid #000000;
    font-family: Arial, Verdana, Tahoma;
    font-size: 11px;
    color: #000000;
    overflow: hidden;
    line-height: 13px;

    img {
      display: block;
    }
  }

  .tooltip_hdr {
    background: #ffffe1;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-top: 1px solid #000000;
    padding: 2px;
    font-family: Arial, Verdana, Tahoma;
    font-size: 12px;
    font-weight: bold;
    color: #000000;
    overflow: hidden;
    line-height: 13px;
  }

  .tooltip_bdy {
    background: #ffffe1;
    border-left: 1px solid #000000;
    border-right: 1px solid #000000;
    border-bottom: 1px solid #000000;
    padding: 2px;
    color: #000000;
    overflow: hidden;
    line-height: 13px;
  }

  .tooltip_tab {
    background: #ffffe1;
    font-family: Arial, Verdana, Tahoma;
    font-size: 12px;
    color: #000000;
    overflow: hidden;
  }

  .tooltip_short_hdr {
    visibility: hidden;
    overflow: hidden;
  }

  .tooltip_short_bdy {
    background: #ffffe1;
    border: 1px solid #000000;
    padding: 2px;
    font-family: Arial, Verdana, Tahoma;
    font-size: 12px;
    color: #000000;
    overflow: hidden;
  }

  .nn_tooltip_hdr {
    overflow: hidden;
    visibility: hidden;
  }

  .nn_tooltip_bdy {
    background: #ffffe1;
    border: 1px solid #000000;
    padding: 2px;
    font-family: Arial, Verdana, Tahoma;
    font-size: 11px;
    color: #000000;
    overflow: hidden;
  }
}

/**
 * SCREENER
 */

body.is-screener,
body.is-groups {
  .fv-container {
    width: 95%;
    padding: 0;
    min-width: 1000px;
  }

  &.is-wide .fv-container {
    max-width: 1575px;
  }
}

body.is-screener {
  .charts-gal {
    border: 3px solid transparent;
  }

  a:hover .charts-gal {
    border-color: #b0d0f0;
  }

  .snapshot-table {
    margin-top: 10px;
    min-width: 335px;
  }

  .snapshot-table2 {
    margin-bottom: 10px;
  }
}

.screener-combo-title {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
}

.screener-combo-text {
  background: #fff;
  height: 19px;
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
}

.screener-combo-text-h {
  height: 19px;
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  background: #fff466;

  option {
    background: #fff;
  }
}

.screener-input-text {
  border: 1px solid #a9a9a9;
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  height: 19px;
  padding: 0 2px;
}

.screener-input-text.h {
  background: #fff466;
}

.screener-input-hide {
  display: inline-block;
}

.screener-combo-button {
  background: #efefef;
  padding: 1px 7px;
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  text-decoration: none;
}

.screener-tabs-top-row,
.screener-tabs-body-row {
  td {
    white-space: nowrap;
  }
}

.screener-tabs-top-row {
  background: url('/gfx/tabs_bg_top3x3.png') repeat-x;

  img {
    display: block;
  }
}

.screener-tabs-body-row {
  background: url('/gfx/tabs_bg_body.png') repeat-x;
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;

  img {
    display: inline-block;
  }
}

.screener-tabs {
  font-family: Verdana, Arial, Tahoma;
  font-size: 10px;
  font-weight: bold;
  white-space: nowrap;
  cursor: pointer;
  border-left: 1px solid #7d7d7d;
  border-right: 1px solid #7d7d7d;

  &.is-selected {
    background: url('/gfx/tabs_bg_selected.gif') repeat-x;
  }

  &.is-yellow {
    background: url('/gfx/tabs_bg_yellow.gif') repeat-x;

    &:hover {
      background: url('/gfx/tabs_bg_yellow_hover.gif') repeat-x;
    }
  }

  &.is-gray {
    background: url('/gfx/tabs_bg_gray.gif') repeat-x;

    &:hover {
      background: url('/gfx/tabs_bg_gray_hover.gif') repeat-x;
    }
  }
}

.screener-link {
  color: #000;
  display: block;
  padding: 3px;
  text-decoration: none;
}

.screener-body-table-nw {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000;
  padding: 0;
  text-decoration: none;
  white-space: nowrap;
}

.body-combo-title {
  font-family: Verdana, Arial, Tahoma;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.body-combo-text,
.screener-combo-text,
.screener-combo-button,
.body-combo-text-h,
.screener-combo-text-h {
  border: 1px solid #767676;
  border-radius: 2px;
}

input.body-combo-text,
input.body-combo-text-h {
  padding: 0 2px;
}

.body-combo-text {
  background: #fff;
  height: 20px;
  font-family: Verdana, Arial, Tahoma;
  font-size: 13px;
  color: #000000;
  text-decoration: none;
}

.body-combo-text-h {
  height: 20px;
  font-family: Verdana, Arial, Tahoma;
  font-size: 13px;
  color: #000000;
  text-decoration: none;
  background: #fff466;
  padding: 0 2px;
}

.screener-filter,
.screener-filter_inv,
.filter_y,
.filter_y_inv {
  text-decoration: none;
  font-family: Verdana, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  padding: 0 6px;
  white-space: nowrap;
}

.screener-filter .arrow-up,
.screener-filter_inv .arrow-up,
.filter_y .arrow-up,
.filter_y_inv .arrow-up {
  display: inline-block;
  width: 0;
  height: 0;
  margin-bottom: 1px;
}

.screener-filter {
  background: #82395d;
  background: linear-gradient(to bottom, #82395d 0%, #82395d 50%, #723250 51%, #723250 100%);
  color: #fff;
}

.screener-filter:hover {
  color: #fff;
  background: #8d4268;
  background: linear-gradient(to bottom, #8d4268 0%, #8d4268 50%, #863d61 51%, #863d61 100%);
}

.screener-filter .arrow-up {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff;
}

.screener-filter_inv {
  background: #fff;
  color: #863d61;
}

.screener-filter_inv:hover {
  background: #f6eef2;
}

.screener-filter_inv .arrow-up {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #863d61;
}

.filter_y {
  background: #82395d;
  background: linear-gradient(to bottom, #82395d 0%, #82395d 50%, #723250 51%, #723250 100%);
  color: #fff04c;
}

.filter_y:hover {
  background: #994871;
  background: linear-gradient(to bottom, #994871 0%, #994871 50%, #873e62 51%, #873e62 100%);
  color: #fff45d;
}

.filter_y .arrow-up {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #fff04c;
}

.filter_y:hover .arrow-up {
  border-top-color: #fff45d;
}

.filter_y_inv {
  background: #fffa8b;
  color: #732649;
}

.filter_y_inv:hover {
  background: #fff86c;
}

.filter_y_inv .arrow-up {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #732649;
}

.screener_arrow {
  position: relative;
  height: 0px;
  width: 0px;
  border: 4px solid;
  border-color: #2e84c1 #2e84c1 transparent transparent;
  transform: rotate(45deg);
  font-size: 0;
  display: inline-block;
}

.screener_arrow:before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  height: 5px;
  width: 8px;
  background-color: #2e84c1;
  transform: rotate(-45deg) translate(2px, 1px);
}

.screener_arrow.is-inactive {
  border-color: #aeaeae #aeaeae transparent transparent;
}

.screener_arrow.is-inactive:before {
  background-color: #aeaeae;
}

.screener_arrow.is-prev {
  transform: rotate(-135deg);
}

.screener_pagination a {
  padding: 3px;
}

a.screener-pages:link {
  color: #1e6dc0;
  text-decoration: none;
}

a.screener-pages:visited {
  color: #1e6dc0;
  text-decoration: none;
}

a.screener-pages:hover {
  color: #1e6dc0;
  text-decoration: none;
  background-color: #e0f0ff;
}

td.filters-border {
  background-color: #d3d3d3;
}

td.filters-cells,
td.filters-cells-h {
  line-height: normal;

  input[type='checkbox'] {
    margin: 3px 3px 3px 4px;
  }
}

td.filters-cells {
  background-color: #eeeeee;
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  border: 1px solid #d3d3d3;

  td {
    background-color: #eeeeee;
    font-family: Verdana, Arial, Tahoma;
    font-size: 11px;
  }
}

td.filters-cells-h {
  background-color: #ffffcc;
  border: 1px solid #d3d3d3;
}

td.filters-cells-dark {
  background-color: #eeeeee;
}

.screener-view-table {
  border-width: 0px 0px 1px 1px;
  border-style: solid;
  border-color: #d3d3d3;
}

td.screener-view {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  background-color: #ffffff;
  background-image: url(/gfx/menu_h_white.gif);
  border-width: 0px 1px 0px 0px;
  border-style: solid;
  border-color: #d3d3d3;
  padding: 0;
  white-space: nowrap;
  text-align: center;

  a {
    color: #000000;
    display: block;
    padding: 2px 3px 1px;
    text-decoration: none;
  }

  /* is-screener override is necessary because this component is used on other pages too e.g. Groups */
  &.is-screener {
    /* Same width buttons look better, but won't fit on small screens */
    /* 100 / 17 views = 5.88 */
    @apply w-[5%] xl:w-[5.88%];
    a {
      /* Give a bit more space to "Performance" */
      @apply xl:px-2;
    }
  }

  &:hover {
    background-image: url(/gfx/menu_h_gray.gif);
  }

  &.screener-view-a {
    font-weight: bold;
    background-image: url(/gfx/menu_h_blue.gif);

    a {
      color: #202020;
    }
  }
}

#screener-content img {
  display: inline-block;
}

.screener-link,
.screener-link-primary {
  color: #000;
  display: block;
  padding: 3px;
  text-decoration: none;
  line-height: normal;
}

.screener-link-primary {
  color: #1e6dc0;
}

.screener-link-primary:hover {
  text-decoration: underline;
}

.pages-combo {
  width: auto;
  font-family: Verdana, Arial, Tahoma;
  font-size: 10px;
  color: #000000;
  text-decoration: none;
  background-color: #efefef;
  border: 1px solid #767676;
}

.snapshot-table {
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #d3d3d3;
}

.snapshot-td {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  border-width: 1px 1px 0px 0px;
  border-style: solid;
  border-color: #d3d3d3;
  line-height: normal;
  padding: 3px 2px;
}

.screener-stats {
  font-family: Lato, sans-serif;
}

.stats-menu {
  color: #575e72;
  font-size: 13px;
  line-height: 20px;
  margin: 20px 0;
}

.stats-menu a {
  color: var(--link-blue);
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  text-decoration: none;
}

.stats-menu a:hover {
  color: #575e72;
}

.stats-menu a.is-active {
  background: #575e72;
  border-radius: 20px;
  color: #fff;
}

a.stats-menu_btn {
  background: #7cb8f6
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAIBAMAAADU/bjBAAAAKlBMVEUAAAD///////////////////////////////////////////////////+Gu8ovAAAADXRSTlMA6e6thuQbBowq86gnfwttTAAAADxJREFUCNdjAIHs0G0MDCWh7jy6dy9NYPC9e4X57t27Bgyydy8yARkL4Ayg1A2IVIVgOydYMQgkC5oxAAAYlBzKVMlfhwAAAABJRU5ErkJggg==')
    10px center no-repeat;
  color: #fff;
  float: right;
  font-size: 13px;
  height: 34px;
  line-height: 34px;
  padding-left: 34px;
  padding-right: 14px;
  position: relative;
  top: -7px;
}

a.stats-menu_btn:hover {
  background-color: #459af2;
  color: #fff;
}

.carousel {
  margin-left: -32px;
  padding: 0 32px;
  position: relative;
  width: 921px;
}

.carousel-wrapper {
  overflow: hidden;
  width: 920px;
}

.carousel_prev,
.carousel_next {
  background: url(/img/screener/stats/sprites.png) no-repeat;
  display: block;
  width: 17px;
  height: 26px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -13px;
}

.carousel_prev:hover {
  background-position: 0 -26px;
}

.carousel_next {
  background-position: -17px 0;
  right: 0;
  left: auto;
}

.carousel_next:hover {
  background-position: -17px -26px;
}

.carousel_pages {
  margin-top: 20px;
  text-align: center;
}

.carousel_page {
  background: url(/img/screener/stats/sprites.png) -39px -10px no-repeat;
  display: inline-block;
  height: 10px;
  margin: 0 5px;
  width: 10px;
}

.carousel_page:hover,
.carousel_page.is-active {
  background-position: -39px 0;
}

.carousel-list {
  -moz-transition: transform 500ms ease;
  -o-transition: transform 500ms ease;
  -webkit-transition: transform 500ms ease;
  transition: transform 500ms ease;
}

.carousel-item {
  border: 1px solid #dee0e6;
  float: left;
  height: 258px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 448px;
}

.carousel-item_header {
  background: #f5f5f5;
  border-bottom: 1px solid #dee0e6;
  position: relative;
}

.carousel-item_header h3 {
  color: #575e72;
  font-size: 15px;
  margin: 0 0 0 19px;
  padding: 0;
  line-height: 48px;
}

.carousel-item_chart-type {
  position: absolute;
  right: 0;
  top: 0;
}

.carousel-item_chart-type a {
  background: center center no-repeat;
  border-left: 1px solid #f5f5f5;
  border-right: 1px solid #f5f5f5;
  border-bottom: 1px solid #e2e4e9;
  display: inline-block;
  width: 50px;
  height: 49px;
}

.carousel-item_chart-type .is-active {
  background-color: #fff;
  border-left: 1px solid #e2e4e9;
  border-right: 1px solid #e2e4e9;
  border-bottom: 1px solid #fff;
}

.carousel-item_chart-type .is-pie {
  background-image: url(/img/screener/stats/pie.png);
}

.carousel-item_chart-type .is-pie.is-active,
.carousel-item_chart-type .is-pie:hover {
  background-image: url(/img/screener/stats/pie_hover.png);
}

.carousel-item_chart-type .is-bar {
  background-image: url(/img/screener/stats/bar.png);
}

.carousel-item_chart-type .is-bar.is-active,
.carousel-item_chart-type .is-bar:hover {
  background-image: url(/img/screener/stats/bar_hover.png);
}

.carousel-item_chart-type .is-table {
  background-image: url(/img/screener/stats/table.png);
  border-right: none;
}

.carousel-item_chart-type .is-table.is-active,
.carousel-item_chart-type .is-table:hover {
  background-image: url(/img/screener/stats/table_hover.png);
}

.carousel-pie {
  height: 209px;
  position: relative;
  padding-top: 30px;
}

.carousel-pie svg {
  margin-left: 50px;
}

.carousel-pie ul {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 250px;
}

.carousel-pie li {
  color: #575e72;
  font-size: 12px;
  list-style: none;
  line-height: 18px;
  margin: 0;
  padding: 0 0 4px 0;
  overflow: hidden;
  white-space: nowrap;
  width: 185px;
}

.carousel-pie_bg {
  color: #fff;
  display: inline-block;
  font-size: 11px;
  text-align: center;
  margin-right: 8px;
  width: 36px;
}

.carousel-bar {
  height: 209px;
  position: relative;
  overflow: auto;
}

.carousel-bar_label {
  color: #575e72;
  font-size: 11px;
  text-align: center;
  padding-top: 5px;
  position: absolute;
  bottom: -15px;
  width: 100%;
}

.carousel-bar ul {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 300px;
}

.carousel-bar li {
  color: #575e72;
  font-size: 12px;
  list-style: none;
  line-height: 12px;
  margin: 0;
  padding: 0 0 4px 0;
  overflow: hidden;
  white-space: nowrap;
  width: 135px;
}

.carousel-bar_bg {
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  width: 12px;
  height: 12px;
  top: 2px;
  position: relative;
}

.carousel-table {
  height: 191px;
  overflow: auto;
  padding: 9px;
}

.carousel-table table {
  border-collapse: collapse;
  color: #3e424f;
  font-size: 12px;
  width: 100%;
}

.carousel-table tr:nth-child(odd) {
  background: #fafafa;
}

.carousel-table th,
.carousel-table td {
  border: 1px solid #dee0e6;
}

.carousel-table th {
  background: #f3f3f3;
  font-weight: normal;
  line-height: 28px;
  padding: 0 9px;
}

.carousel-table th.is-active {
  font-weight: bold;
}

.carousel-table td {
  line-height: 25px;
  padding: 0 9px;
  text-align: center;
}

.carousel-table td.is-active {
  background: #fffce5;
  font-weight: bold;
}

.stats-table {
  display: flex;
  flex-wrap: wrap;
  margin-right: -3px;
}

.stats-table_item {
  border: 1px solid #dee0e6;
  color: #363a46;
  font-size: 13px;
  width: 25%;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 39px;
}

.stats-table_item.is-hovered .stats-table_label,
.stats-table_item.is-hovered .stats-table_content {
  background: #fffce5 !important;
}

.stats-table_label {
  background: #fafafa;
  border-right: 1px solid #dee0e6;
  box-sizing: border-box;
  display: inline-block;
  font-weight: bold;
  padding-left: 10px;
  width: 50%;
}

.stats-table_content {
  box-sizing: border-box;
  display: inline-block;
  padding-left: 10px;
  width: 50%;
}

.stats-perf-loader {
  height: 54px;
  margin-bottom: 40px;
  margin-top: 30px;
  position: relative;
  text-align: center;
}

.stats-perf-loader_bar {
  border: 1px solid #dee0e6;
  height: 6px;
  width: 100%;
  padding: 1px;
  position: absolute;
  top: 22px;
  left: 0;
}

.stats-perf-loader_bar div {
  background: #dee0e6;
  height: 100%;
}

.stats-perf-loader_button {
  background: var(--link-blue);
  border: none;
  color: #fff;
  height: 54px;
  line-height: 54px;
  padding: 0 20px;
  text-align: center;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
  position: relative;
}

.stats-perf-loader_button:hover,
.stats-perf-loader_button:active,
.stats-perf-loader_button:focus {
  background: #1f8bf1;
}

.stats-perf-loader_button .icon.is-perf {
  background: url(/img/screener/stats/sprites.png) -34px -41px no-repeat;
  display: inline-block;
  height: 11px;
  padding-right: 5px;
  width: 15px;
  top: 1px;
  position: relative;
}

.stats-perf-chart {
  border: 1px solid #dee0e6;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
}

.stats-perf-chart h2 {
  color: #575e72;
  font-size: 15px;
  margin: 12px 0;
}

.stats-perf-chart #chart {
  position: relative;
  /* width: 802px; */
  margin: 0 auto;
}

.stats-perf-chart #chart .pane {
  border: none;
}

.stats-perf-chart #chart .pane img,
.stats-perf-chart #chart .pane canvas {
  border-color: #fff;
}

.stats-disclaimer {
  color: #575e72;
  font-size: 12px;
  margin-top: 5px;
  text-align: left;
}

td.screener-tickers {
  width: 100%;
  background-color: #505050;
  line-height: normal;
  color: #ffffff;
  font-family: Arial;
  text-align: center;
  vertical-align: top;
}

/**
 * GROUPS
 */

#groups {
  .futures {
    .bar-chart {
      margin: auto;
    }
  }
}

#groups {
  font-family: Lato, sans-serif;
}

#groups * {
  box-sizing: border-box;
}

#groups .bar-chart {
  margin: 5px;
}

#groups .bar-chart h1 {
  color: #4c5261;
  font-size: 14px;
  padding-left: 100px;
  margin: 10px 0;
  text-align: left;
}

#groups .bar-chart rect {
  stroke: white;
}

#groups .bar-chart text {
  fill: white;
}

#groups .bar-chart text.name {
  fill: #000;
}

#groups .bar-chart .score.is-outside {
  color: #000;
}

#groups .bar-chart line {
  stroke: #c1c1c1;
}

#groups .bar-chart .rule {
  fill: #000;
}

#groups .bar-chart .rect {
  color: #fff;
  cursor: default;
  text-align: right;
  line-height: 12px;
  transition: all 0.5s ease-in-out;
}

#groups .bar-chart .rect .value {
  position: absolute;
  text-align: right;
  padding-right: 2px;
  line-height: 16px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  text-shadow: 0.5px 0.9px 0 rgba(0, 0, 0, 0.25);
}

#groups .bar-chart .rect .value.is-outside {
  color: #000;
  text-align: left;
  text-shadow: none;
  width: 100px;
}

#groups .bar-chart .rect .label {
  color: #4c5261;
  font-size: 12px;
  line-height: 15px;
  left: -230px;
  padding-right: 6px;
  position: absolute;
  text-align: right;
  width: 230px;
}

#groups .bar-chart .rect.is-inactive {
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
}

#groups .bar-chart .rect:hover {
  cursor: pointer;
}

#groups .bar-chart .bar-chart_tick {
  border-left: 1px dashed #cbced5;
  height: 100.5%;
  padding-bottom: 5px;
  width: 1px;
}

#groups .bar-chart .bar-chart_tick div {
  width: 30px;
  text-align: center;
  margin: 0 auto 0 -15px;
  font-size: 11px;
  position: absolute;
  top: 100%;
  color: #4c5261;
  opacity: 0.5;
}

#groups .bar-chart .bar-chart_top {
  border-top: 1px dashed #cbced5;
  position: absolute;
  height: 1px;
  top: -2px;
}

#groups .bar-chart .bar-chart_bottom {
  border-bottom: 1px dashed #cbced5;
  position: absolute;
  height: 1px;
  top: 100.5%;
}

.body-table-nw {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  white-space: nowrap;
  line-height: normal;
  padding: 3px;
}

.body-table-nw.is-positive {
  color: #008800;
}

.body-table-nw.is-negative {
  color: #aa0000;
}

.body-text {
  font-family: Verdana, Arial, Tahoma;
  font-size: 13px;
  color: #000000;
  text-decoration: none;
}

select.statements-select {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  padding: 1px 2px;
  border: 1px solid #767676;
  border-radius: 2px;
}

[type='submit'],
[type='reset'],
[type='button'] {
  &.portfolio-edit {
    padding: 1px 6px;
    border-radius: 2px;
    background-color: #eeeeee;

    &:hover:not(:disabled) {
      background-color: #e0e0e0;
    }
  }
}

.login-title {
  font-family: Verdana, Arial, Tahoma;
  font-size: 14px;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.login-text {
  font-family: Verdana, Arial, Tahoma;
  font-size: 12px;
  color: #000000;
  text-decoration: none;
}

.full-width-inputs input,
.full-width-inputs select,
.full-width-inputs textarea {
  width: 100%;
}

.save-to-portfolio-form {
  input,
  button,
  select {
    padding: 1px 2px;
    color: #000000;
    text-decoration: none;
    border: 1px solid #767676;
    border-radius: 2px;
  }

  [type='submit'] {
    &:hover {
      background-color: #e0e0e0;
    }
  }
}

.calendar {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  line-height: normal;

  td {
    padding: 1px;
  }
}

.calendar-title {
  font-family: Verdana, Arial, Tahoma;
  font-size: 20px;
  margin-top: 34px;
  margin-bottom: 14px;
  color: #a3a3a3;
  font-weight: bold;
  text-align: center;
}

tr.calendar-header {
  background-color: #dadada;

  th {
    font-weight: 400;
  }
}

tr.calendar-future {
  background-color: #fff4da;
}

tr.calendar-now {
  background-color: #ffe8aa;
}

.is-old-index {
  .calendar .table-light-row-cp:hover,
  tr.calendar-future:hover,
  tr.calendar-now:hover {
    background-color: #e0f0ff;
    cursor: pointer;
  }
}

.portfolio-links {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #1e6dc0;
  text-decoration: none;
}

.portfolio-text {
  font-family: Verdana, Arial, Tahoma;
  font-size: 12px;
  color: #000000;
  font-weight: bold;
}

.portfolio-combo,
.portfolio-combo-h {
  font-family: Verdana, Arial, Tahoma;
  font-size: 12px;
  padding: 1px 2px;
  color: #000000;
  text-decoration: none;
  background-color: #f4f4f4;
  border: 1px solid #767676;
  border-radius: 2px;
}

.portfolio-combo-h {
  background: #fff466;
}

.portfolio-edit {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  width: 100%;
  border: 1px solid #767676;
  border-radius: 2px;
  line-height: normal;
}

input {
  &.portfolio-edit {
    padding: 1px 2px;
  }
}

.portfolio-edit-text {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
}

.portfolio-error-text {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: Red;
  text-decoration: none;
  height: 15px;
}

tr.portfolio-dark-row {
  background-color: #f5f5f5;
}

tr.portfolio-dark-row-h {
  background-color: #e0f0ff;
}

tr.portfolio-dark-row-cp {
  background-color: #f5f5f5;
}

tr.portfolio-dark-row-cp:hover {
  background-color: #e0f0ff;
}

tr.portfolio-dark-row-cp-h {
  background-color: #e0f0ff;
}

tr.portfolio-dark-row-err {
  background-color: #ffaaaa;
}

tr.portfolio-row-total {
  background-color: #fafada;
}

.body-table {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
  color: #000000;
  text-decoration: none;
  line-height: normal;

  td,
  th {
    padding: 2px;
  }
}

.tabchrtbdy {
  background: url('/gfx/chart_hover_bg2.gif') repeat-x;
  border: 1px solid #000000;
  font-family: Arial, Verdana, Tahoma;
  font-size: 11px;
  color: #000000;
  overflow: hidden;
}

.hp_label-container {
  white-space: nowrap;

  &.is-single-column {
    @apply flex w-full items-center justify-between;
  }
  &.is-highlighted {
    @apply bg-yellow-100 dark:bg-yellow-700;

    a.tab-link {
      @apply text-blue-600 dark:text-blue-200;
    }
  }
}
.hoverchart .fv-label.is-yellow {
  @apply inline-block bg-yellow-100 leading-none text-default dark:bg-yellow-700 dark:text-white;
}
