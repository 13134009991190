.blog-post {
  color: #62697d;
  .blog-header {
    background: #040303 bottom no-repeat;
    background-size: cover;
    height: 350px;
    .gradient {
      background: url('/img/blog/header-shadow.png') bottom repeat-x;
      height: 350px;
    }
    .content {
      padding-top: 215px;
    }
    h1 {
      color: #fff;
      font-size: 60px;
      margin: 0;
    }
  }
  .content {
    width: 600px;
    margin: 0 auto;
  }
  p {
    font-size: 16px;
    line-height: 26px;
    margin: 22px 0;
    &.is-first {
      margin-top: 0;
    }
    &.is-last {
      margin-bottom: 0;
    }
  }
  a {
    color: #5faaf4;
    text-decoration: underline;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
  hr {
    background: #dddee1;
    border: none;
    font-size: 0;
    height: 1px;
    margin: 40px 0;
  }
  .img-right {
    float: right;
    padding-left: 30px;
  }
  .time {
    background: url('/img/icon_time.png') left center no-repeat;
    font-size: 12px;
    padding-left: 16px;
  }
  .well {
    background: #f9fafb;
    margin-left: -180px;
    padding: 40px 20px 40px 40px;
    width: 960px;
  }
  .check-marks {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      background: no-repeat left center;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAIBAMAAAA/ygPCAAAAJFBMVEUAAABfqvRfqvRfqvRfqvRfqvRfqvRfqvRfqvRfqvRfqvRfqvSm/5SZAAAAC3RSTlMAZu4zEcy7qpl3IhHJeFQAAAAzSURBVAjXYwABIwEQyay9i4F5AoPRbgEGpx3M2hsZGFx3FwE5DCzRu4EcEBesjiURSAAAENcKETItyNMAAAAASUVORK5CYII=');
      font-style: italic;
      line-height: 26px;
      padding-left: 20px;
    }
  }
}
