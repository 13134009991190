.notice-message {
  @apply my-2.5 flex justify-center;
}

.notice-message_content {
  @apply relative flex w-[800px] border border-[#FEE500] bg-[#FFFCE5] p-2 pr-10 text-[#363A46];
}

.notice-message_text {
  @apply ml-2 grow text-center text-xs;
  font-family: Lato;
}

.notice-message_close {
  @apply absolute right-2 top-1.5 flex h-6 w-6 items-center justify-center font-bold;
}
