@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src:
    url('/fonts/lato-v17-latin-ext_latin-regular.woff2') format('woff2'),
    url('/fonts/lato-v17-latin-ext_latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src:
    url('/fonts/lato-v17-latin-ext_latin-700.woff2') format('woff2'),
    url('/fonts/lato-v17-latin-ext_latin-700.woff') format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src:
    url('/fonts/lato-v17-latin-ext_latin-900.woff2') format('woff2'),
    url('/fonts/lato-v17-latin-ext_latin-900.woff') format('woff');
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family) !important;
  font-size: 14px;
  line-height: 1.428571429 !important;
  color: var(--text-color);
  background-color: #fff;
  min-width: 1000px;

  img {
    display: inline-block;
    max-width: none;
    height: initial;
  }

  --core-heading-font-weight: bold;
  --core-heading-y-margin: 1.33em;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: var(--core-heading-font-weight);
    margin: var(--core-heading-y-margin) 0;
  }

  .is-js-module {
    --core-heading-font-weight: normal;
    --core-heading-y-margin: 0;
  }
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: var(--link-color);
  text-decoration: none;

  &:hover,
  &:focus {
    color: var(--link-hover-color);
    text-decoration: none;
  }
}

.fv-container {
  @apply px-5;
  width: 100%;
  min-width: 1000px;
  max-width: 1425px;
  margin: 0 auto;
  &.is-wide {
    max-width: 1615px;
  }
  &.is-full-width {
    max-width: 100%;
  }
  &.has-responsive-padding {
    @apply px-2.5 xl:px-5;
  }
  html.is-responsive & {
    min-width: auto;
  }
}

.content.is-full-width .fv-container {
  width: 100%;
  min-width: 0;
  max-width: none;
  padding: 0;
}

.content.is-index .fv-container {
  min-width: 999px;
  width: 95%;
  padding: 0;
}
@media (min-width: 1500px) {
  .content.is-index .fv-container {
    width: 1425px;
  }
}

@media (max-width: 1024px) {
  body.is-index.is-elite .fv-container {
    width: auto;
    padding: 0 5px;
  }

  body.is-futures,
  body.is-forex,
  body.is-crypto {
    &,
    .header-container,
    .fv-container {
      min-width: 994px;
    }
  }
  body.is-futures .fv-container,
  body.is-forex .fv-container,
  body.is-crypto .fv-container:not(.chart-menu_charts) {
    padding: 0 5px;
  }

  body.is-futures .fv-container {
    overflow: hidden;
  }
}

.content-header {
  color: #575e72;
  margin: 0 auto 35px auto;
  text-align: center;

  h1 {
    font-size: 30px;
    margin-bottom: 0;
    margin-top: 28px;
  }

  p {
    color: #62697d;
    font-size: 13px;
    line-height: 18px;
    margin-top: 4px;
  }

  a {
    color: var(--link-blue);
    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &.is-large {
    p {
      font-size: 16px;
    }
  }
}

.is-positive {
  color: #008800;
}

.is-negative {
  color: #aa0000;
}

.perf-chart-container {
  display: flex;
  justify-content: center;

  .bar-chart,
  .vertical-bar-chart {
    flex-shrink: 0;
  }
}

.perf-page,
.modal {
  input:not([type='checkbox']),
  select,
  button {
    border-width: 1px;
    border-color: darkgray;
    padding: 3px;
    border-radius: 2px;
  }

  button,
  input[type='submit'] {
    background: #ededed;
  }
}
