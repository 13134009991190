.about {
  margin: 0 auto;
  padding: 40px 0 0 0;
  width: 600px;

  h2,
  p,
  ul {
    color: #575e72;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 18px;
  }

  p {
    font-size: 13px;
    line-height: 18px;
    margin: 5px 0;
  }

  ul {
    margin: 14px 0;
    padding-left: 40px;
    list-style-type: disc;
  }
}
