.tio {
  display: inline-block;
  width: 17px;
  height: 17px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
  top: -1px;
  position: relative;
  margin-right: 2px;
}
