.styled-table-new {
  @apply font-sans text-2xs;

  td,
  th {
    @apply h-5 px-1.5 py-0.5 leading-4;
  }

  td {
    @apply text-default;
  }

  th {
    @apply text-gray-600 font-normal dark:text-gray-200;
  }

  &.is-rounded {
    @apply border-separate border-spacing-0 rounded-md;
  }

  &.is-medium {
    td {
      @apply h-6 py-1;
    }
  }

  &.is-large {
    td {
      @apply h-7 py-1.5;
    }
  }

  &.is-condensed td,
  &.is-condensed th {
    @apply px-px first:pl-1.5 last:pr-1.5;
  }

  &.is-tabular-nums {
    td[align='right'],
    td.text-right {
      @apply tabular-nums;
    }
  }
}

.table-header {
  @apply first:rounded-l-md last:rounded-r-md;

  &.is-selected {
    @apply text-gray-800 font-medium dark:text-gray-50;
  }

  &:hover {
    @apply hover:bg-gray-200/30 hover:text-black dark:hover:bg-gray-500/30 dark:hover:text-white;
  }
}

.styled-row {
  td {
    @apply bg-primary;
  }

  &.is-clickable td {
    @apply cursor-pointer;
  }

  &.is-hoverable:hover td,
  &.is-striped.is-hoverable:hover td {
    &:not(.is-separator) {
      @apply bg-blue-50 dark:bg-gray-700;
    }
  }

  &.is-bordered {
    td {
      @apply border-gray-100 first:border-l last:border-r dark:border-gray-600;
    }

    &.is-first td,
    &:first-of-type td {
      @apply border-t;
    }
    &.is-last td,
    &:last-of-type td {
      @apply border-b;
    }
  }
  &.is-border-top {
    @apply relative;

    td {
      @apply border-t;
    }

    /* hack to cover up borders intersection */
    &:not(:first-child, .is-first) td {
      @apply border-t-gray-100 dark:border-t-gray-700;
      @apply first:before:absolute first:before:left-0 first:before:top-0 first:before:h-px first:before:w-px dark:first:before:bg-gray-600;
      @apply last:after:absolute last:after:right-0 last:after:top-0 last:after:h-px last:after:w-px dark:last:after:bg-gray-600;

      /* Using tailwind classname, this isn’t a table modifier */
      &.sticky {
        @apply first:before:-left-px first:before:-top-px;
        @apply last:after:bottom-px last:after:right-px;
      }
    }
  }

  &.is-rounded {
    &.is-first td,
    &:first-of-type td {
      @apply first:rounded-tl-md last:rounded-tr-md;
    }
    &.is-last td,
    &:last-of-type td {
      @apply first:rounded-bl-md last:rounded-br-md;
    }
  }

  &.is-striped:nth-child(even) td {
    @apply bg-gray-50 dark:bg-gray-750;
  }

  &.is-highlighted td {
    @apply bg-gray-50 dark:bg-gray-900;
  }

  &.is-hover-borders:hover {
    td {
      @apply border-accent;
    }

    & + tr td {
      @apply border-t-accent;
    }
  }
}

.cell-right {
  @apply text-right;
}
.cell-left {
  @apply text-left;
}
