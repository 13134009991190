.form {
  color: #62697d;
  font-size: 12px;
  padding: 20px;

  a {
    color: var(--link-blue);
    font-size: 12px;
    &:hover {
      color: var(--link-blue-hover);
    }
  }

  &.is-vertical {
    background: #f5f5f5;

    .input,
    .checkbox,
    .button,
    .error {
      margin-bottom: 10px;
      width: 100%;
    }

    .error {
      margin-top: -5px;
    }
  }

  &.is-large {
    font-size: 14px;
    .input {
      font-size: 14px;
      height: 58px;
      input,
      select,
      textarea,
      .backtest-input_value {
        font-size: 16px;
      }
    }
    .checkbox {
      font-size: 14px;
      input {
        top: 2px;
      }
    }
    a {
      font-size: 14px;
    }
    .button {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
  }
}

.input {
  background: #fff;
  border: 2px solid #fff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #62697d;
  display: inline-block;
  font-size: 12px;
  height: 48px;
  padding: 4px 0 0 0;
  margin-right: 4px;
  vertical-align: top;
  position: relative;
  &.has-textarea {
    height: auto;
    width: 100%;
  }
  span {
    padding: 0 9px;
  }
  input,
  select,
  textarea,
  .backtest-input_value {
    border: none;
    color: #3e424f;
    display: block;
    font-size: 13px;
    font-weight: bold;
    outline: none;
    padding: 1px 9px 0 9px;
    margin-top: 1px;
    width: 100%;

    &::-webkit-input-placeholder {
      font-weight: normal;
    }
    &:-moz-placeholder {
      font-weight: normal;
    }
    &::-moz-placeholder {
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      font-weight: normal;
    }
  }
  select {
    padding: 1px 5px 0 5px;
  }
  textarea {
    height: 150px;
  }
  &.is-gray {
    background: #f5f5f5;
    border-color: #f5f5f5;
    input,
    select {
      background: #f5f5f5;
    }
  }
  &.has-error {
    border-color: #f87b7a;
    color: #f87b7a;
  }
  &.has-no-label {
    padding: 0;
    vertical-align: top;
    select {
      height: 44px;
      margin: 0;
      font-size: 24px;
    }
  }
}

.checkbox {
  color: #62697d;
  display: inline-block;
  font-size: 12px;
  input {
    position: relative;
    top: 3px;
  }
}

.error {
  background: url('data:image/gif;base64,R0lGODlhDgANALMAAP7v7/3f3/zAv/qYl/u4t/uop/mQj/3Pz//39/7n5/mIh////wAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAAOAA0AAAQ9UMmZ0ryXrEXwNciGGJ4kbJtQgou0jN6xucuBDSi9DJc8KyibpIAqogqSQFG3CCiIS2CxADBaF4DE1ZiIAAA7')
    8px center no-repeat;
  background-color: #f98887; /* ^^ IE8 fix */
  border: 1px solid #f87b7a;
  color: #fff;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  margin-top: 8px;
  padding-left: 31px;
  padding-top: 3px;
  padding-bottom: 3px;
}
