.button {
  background: #fff;
  border: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  color: #3e424f;
  display: inline-block;
  font-size: 12px;
  height: 34px;
  line-height: 34px;
  margin-bottom: 4px;
  outline: 0;
  padding: 0 15px;
  text-align: center;

  &:hover {
    background: #e6e6e6;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    color: #282b33;
  }

  &.is-primary {
    color: #fff;
    background: var(--link-blue);
    box-shadow: none;
    font-weight: bold;

    &:hover {
      color: #fff;
      background: var(--link-blue-hover);
    }
  }

  &.is-delete {
    --accent: #f87b7a;
    --accent-hover: #f64c4c;

    color: var(--accent);

    &:hover {
      color: var(--accent-hover);
    }
  }

  &.is-danger {
    --accent: #f87b7a;
    --accent-hover: #f64c4c;

    background: var(--accent);
    color: #fff;

    &:hover {
      background: var(--accent-hover);
      color: #fff;
    }
  }

  &.is-gray {
    --accent: #f5f5f5;
    --accent-hover: #dbdbdb;

    background: var(--accent);

    &:hover {
      background: var(--accent-hover);
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    }
  }

  &.is-large {
    font-size: 13px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
  }

  &.has-no-margin {
    margin: 0 !important;
  }

  &.has-margin-right {
    margin-right: 10px;
  }

  .icon {
    cursor: pointer;
    margin-right: 5px;

    &.is-duplicate {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANBAMAAACAxflPAAAALVBMVEUAAAA+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk8+Qk+Oc8L7AAAAD3RSTlMARLt3zJkRqjMi7t1ViGbUSC0hAAAAVElEQVQI12NgYBCUYACBFGVrEMXmasAE5j40YCpSn8BwS8UgxVFUgEGBgUNDgRlEzzKE0KbGB8C0IQMDkC5S2gimEwUDwTQDg7GgoCiIPqSkpDQBALmQDcFFqhvUAAAAAElFTkSuQmCC');
      width: 13px;
      height: 13px;
    }

    &.is-delete {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAALBAMAAAC0QAErAAAAJFBMVEUAAAD4e3r4e3r4e3r4e3r4e3r4e3r4e3r4e3r4e3r4e3r4e3r1kr0TAAAADHRSTlMAu3dEiGYz7pkiEcxT6FetAAAAO0lEQVQI12NgYFJawMDAoKEkwMDgWChYKMaQbWxs1MXAaBkQrMDAqCwgjEaGOTgDSQYGZgUGJkFBwQkAIqIIC9U7YYUAAAAASUVORK5CYII=');
      width: 10px;
      height: 11px;
    }

    &.is-check-mark {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAJCAYAAAAGuM1UAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAGBJREFUeNpi+P//PwMRuAOI00BsFgbCYCYQp0HZ7wmZPPM/ArwDYmOQoCAQn4FiQXyKQeIgiVVIEjBNWBXDNBhDBZEVYFUM04BNE1bFyBrQNWFVjK4Bpgnkj1BcIQcQYABVN0M3rlnJQgAAAABJRU5ErkJggg==');
      width: 12px;
      height: 9px;
      top: 1px;

      @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAASBAMAAACzwgOtAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAACnRSTlMAEcwz7pmqiHci9C+B8gAAAFxJREFUCNdjgIMZAgg2s5cigmOyapEAQmLVqgSExKpVAUAxqARYWZQiVAJIswJF4BJVQCGYBEMWUEwYKgEWVIFKgJWvgkhApCASECm4BMIOmBRYAi6liOSVVKgEACCdLeQEYNDgAAAAAElFTkSuQmCC');
        background-size: 12px 9px;
      }
    }

    &.is-create {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKBAMAAAB/HNKOAAAAG1BMVEUAAAD////////////////////////////////rTT7CAAAACHRSTlMAd2buzLszEbwqLHAAAAAmSURBVAjXY2BgUCxgAIIOAVRSoqOjowVMdjAkCnYECqGpsRBgAAAe5wmb6SqujgAAAABJRU5ErkJggg==');
      width: 10px;
      height: 10px;
      top: 1px;

      @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAJ1BMVEUAAAD///////////////////////////////////////////////+uPUo5AAAADHRSTlMARLt37iJVM8yqmREtKXBEAAAAPElEQVQI12NABexKSgVQJueZMwZQJhMZTJEzUODKYANjHsTBzEGoZRUUlDhzZqGg4AayLIYAtjNHBIAUAGMvPUKZvBBRAAAAAElFTkSuQmCC');
        background-size: 10px 10px;
      }
    }

    &.is-presets {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALBAMAAABSacpvAAAAFVBMVEUAAAD///////////////////////9Iz20EAAAABnRSTlMAu0TddxGSWJheAAAAJElEQVQI12NwSwOB5AAGsTQwUEAwhBhAgBHIEAKJKZImAjcZALO0EPYkBqwkAAAAAElFTkSuQmCC');
      width: 15px;
      height: 11px;
      top: 1px;

      @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAWBAMAAAAlTTH8AAAAElBMVEUAAAD///////////////////8+Uq06AAAABXRSTlMAd+6IRBUU9PQAAAA5SURBVBjTY2BUDUUARQYG11Bk4MAgisIXIJ1vwIAApkC+ABJfFMQHQphqDD66+kGgH9U/6OGFHp4Ae/k3m3Jj/SsAAAAASUVORK5CYII=');
        background-size: 15px 11px;
      }
    }
  }
}
