.chart-idea-note_wrapper {
  textarea {
    @apply min-h-6.5 max-w-full py-0.5;
  }

  /**
    * This logic would require lot more JS than one liner css
    */
  textarea:empty:not(:focus) {
    @apply max-h-6.5 max-w-48 resize-none overflow-hidden;
  }
}
