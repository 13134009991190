.charts-page {
  flex-grow: 1;
  overflow: hidden;

  &.has-sidebar {
    display: flex;

    #app {
      padding: 0;
    }
  }

  #app {
    display: flex;
    flex-grow: 1;
    padding: 0 25px 25px;

    &:fullscreen,
    &:-webkit-full-screen {
      background: #fff;

      .dark & {
        background: #262931;
      }
    }
  }

  .layout {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: flex-end;
  }

  .tools {
    background: #f5f5f5;
    border: none;
    height: 39px;
    left: 0;
    position: relative;
    width: 100%;
    z-index: 10;
    margin-bottom: 20px;

    .dark &  {
      background: #363a46;
    }

    .icon {
      border: none;
      width: calc(100% / 18);
      height: 39px;
      text-align: center;

      &:hover,
      &.active {
        background: #dddddd;
        width: calc(100% / 18);
        border: none;

        .dark & {
          background: #404553;
        }
      }
    }
  }

  #chart {
    display: flex;
    flex-direction: column;
    flex: 1;

    .charts {
      display: flex;
      flex-wrap: wrap;
    }

    .chart {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      border: none;

      &.active {
        position: relative;
        z-index: 10;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 20;
          box-shadow: inset 0 0 0 2px rgb(2 132 199 / 5%);
          pointer-events: none;
        }
      }

      .dark & {
        background-color: #262931;
      }
    }

    .pane {
      border: none;

      img,
      canvas {
        border: none;
      }

      .canvas {
        border: none;
        border-top: 1px solid #d9d9df;
        overflow: hidden;

        .dark & {
          border-color: #363a46;
        }

        .second {
          top: 1px;
          left: 0;
        }
      }
    }
  }

  .chart-idea-date {
    position: absolute;
    top: 95px;
    text-align: center;
    width: 100%;
    font-size: 16px;
    color: #838383;
    font-weight: bold;
  }

  .chart-idea-note {
    background: #d9d9df;
    padding: 20px;
    margin-top: -10px;
    margin-left: 25px;
    margin-bottom: 25px;
  }

  .chart-idea-note_text {
    background: #fff;
    border: 2px solid #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    color: #62697d;
    font-size: 12px;
    height: 48px;
    padding: 10px;
  }
}
