#notifications-container {
  display: none;
  position: sticky;
  top: 0px;
  z-index: 100000;
}

#notifications-container > div {
  background: #fd656a;
  color: #ffffff;
  font-family: Lato, Verdana, sans-serif;
  font-size: 14px;
  padding: 8px 0;
  text-align: center;
}

#notifications-container > div a {
  color: #fff;
  text-decoration: underline;
}

#notifications-container > div a:hover,
#notifications-container > div a:active,
#notifications-container > div a:focus {
  text-decoration: none;
}

#new-feature {
  background: #4a8fd1;
  color: #ffffff;
  display: none;
  font-family: Lato, Verdana, sans-serif;
  font-size: 14px;
  padding: 8px 0;
  text-align: center;
}

#new-feature a {
  color: #fff;
  text-decoration: underline;
}

#new-feature a:hover,
#new-feature a:active,
#new-feature a:focus {
  text-decoration: none;
}

#new-feature .close {
  color: #e3ebf6;
  line-height: 18px;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  margin-top: -1px;
  text-decoration: none;
}

#new-feature .close:hover,
#new-feature .close:active,
#new-feature .close:focus {
  color: #fff;
}
