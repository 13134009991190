.styled-table {
  td,
  th {
    border: 1px solid #e2e4e9;
    padding: 0 15px;
  }
  thead th {
    line-height: 28px;
    padding: 0 5px;
  }
  th {
    background: #f5f5f5;
  }
  td {
    line-height: 39px;
  }
  a {
    color: var(--link-blue);
    display: block;
    font-weight: bold;
    margin: 0 -15px;
    padding: 0 15px;
  }

  &.is-condensed {
    font-size: 11px;
    td,
    th {
      padding: 0 7px;
    }
    td {
      line-height: 28px;
    }
  }

  &.is-full-width {
    width: 100%;
  }

  &.is-striped {
    tr:nth-child(even) {
      background: #fcfcfc;
    }
  }

  &.has-full-row-links td {
    cursor: pointer;
  }

  &.is-hoverable tr:hover td,
  &.is-highlightable td:hover,
  .is-highlighted {
    background: #fffce5 !important;
  }

  .has-no-padding {
    padding: 0;
  }

  .has-left-align {
    text-align: left;
  }

  .has-center-align {
    text-align: center;
  }

  .has-right-align {
    text-align: right;
  }

  .reactable-pagination {
    td,
    th,
    tr:hover td {
      background: #fff !important;
      border: none;
      padding: 25px 0;
      text-align: center;
    }
    a {
      border: 1px solid #f0f1f4;
      color: var(--link-blue);
      display: inline-block;
      font-size: 13px;
      font-weight: normal;
      line-height: 28px;
      margin: 0 -1px 0 0;
      padding: 0;
      position: relative;
      text-align: center;
      width: 38px;
      &:hover {
        border: 1px solid #e2e4e9;
        z-index: 10;
      }
    }
    .reactable-current-page {
      border: 1px solid #e2e4e9;
      color: #3e424f;
      font-weight: bold;
      z-index: 100;
      &:before {
        content: '';
        width: 100%;
        background-color: #62697d;
        height: 3px;
        position: absolute;
        bottom: -1px;
        left: -1px;
        box-sizing: content-box;
        padding: 0 1px;
        z-index: 1000;
      }
    }
  }
}
