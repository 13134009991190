.react-calendar {
  overflow: hidden;
  font-size: 11px;
  background: #fff;
  width: 235px;
  color: rgb(98, 105, 125);
}

.react-calendar button {
  background: #fff;
  border: 1px solid #e2e4e9;
  border-top-width: 0;
  border-right-width: 0;
  line-height: 23px;
  outline: none;
  border-radius: 0;
}

.react-calendar .react-calendar__year-view__months__month:nth-child(3n) {
  border-right-width: 1px;
}

.react-calendar .react-calendar__month-view__days__day:nth-child(7n) {
  border-right-width: 1px;
}

.react-calendar button:hover {
  background: rgb(238, 238, 238);
}

.react-calendar__month-view__weekdays {
  border-bottom: 1px solid #e2e4e9;
  line-height: 29px;
}

.react-calendar__month-view__weekdays__weekday {
  border-left: 1px solid #e2e4e9;
  text-align: center;
}

.react-calendar__month-view__weekdays__weekday:nth-child(7) {
  border-right: 1px solid #e2e4e9;
}

.react-calendar__month-view__weekdays__weekday abbr {
  border: none;
  font-weight: bold;
  text-decoration: none;
}

.react-calendar__year-view__months__month[disabled],
.react-calendar__month-view__days__day[disabled] {
  color: rgba(98, 105, 125, 0.3);
}

.react-calendar__year-view__months__month[disabled]:hover,
.react-calendar__month-view__days__day[disabled]:hover {
  background: rgba(238, 238, 238, 0.3);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: rgba(98, 105, 125, 0.7);
}

.react-calendar__navigation {
  border-top: 1px solid #e2e4e9;
  display: flex;
  font-weight: bold;
}

.react-calendar__navigation button {
  border-left: 1px solid #e2e4e9;
  line-height: 26px;
}

.react-calendar__navigation button:last-child {
  border-right: 1px solid #e2e4e9;
}

.react-calendar__navigation__arrow {
  width: 14.2857%;
}
.react-calendar__navigation__arrow[disabled] {
  color: rgba(98, 105, 125, 0.3);
}
.react-calendar__navigation__arrow[disabled]:hover {
  background: rgba(238, 238, 238, 0.3);
}

.react-calendar .react-calendar__tile--active {
  background: rgb(238, 238, 238);
  color: #000;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-gray-300;
}

.dark {
  .react-calendar,
  .react-calendar button {
    @apply bg-gray-800 text-gray-50;
  }

  .react-calendar button,
  .react-calendar__navigation,
  .react-calendar__month-view__weekdays,
  .react-calendar__month-view__weekdays__weekday {
    @apply border-gray-600;
  }

  button.react-calendar__tile--active {
    @apply bg-gray-900 text-white;
  }

  .react-calendar button {
    @apply hover:bg-gray-900 hover:text-white;
  }

  button.react-calendar__month-view__days__day--neighboringMonth {
    @apply text-gray-400;
  }
}
