.search-results_tickers {
  background: #f5f5f5;
  padding: 1px 0 5px 0;
  position: relative;
}

.search-results_tickers_ticker {
  background: #fff;
  color: #62697d;
  display: inline-block;
  width: 183px;
  height: 183px;
  font-size: 12px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
  float: left;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);

  h5 {
    color: var(--link-blue);
    font-size: 30px;
    font-weight: normal;
    margin: 0 0 30px 0;
  }

  &:hover {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
}

.search-results_tickers_ticker_truncate {
  display: block;
  width: 163px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 1px;
}

.styled-table td.search-results-profile-internal-table {
  border: none;
  padding: 0;

  tr:nth-child(2) td {
    border: none;
    line-height: 16px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
}
