tr.nn,
tr.nn-home,
.ic-banner {
  [ic_placementid] > div {
    @apply flex items-baseline;

    a {
      @apply truncate;
    }

    .news-link-source {
      @apply ml-1 shrink-0;
    }
  }

  td {
    max-width: 1024px;
    overflow: hidden;
  }
}

#IC_D_custom_3x8_1,
#IC_D_custom_3x8_2,
#IC_D_custom_3x8_3,
#IC_D_custom_3x1_1 {
  overflow: hidden;
}
