#modal {
  display: none;
}

.modal.overlay {
  font-family: Verdana, sans-serif;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.25);
}

@media (max-width: 500px), (max-height: 500px) {
  .modal.overlay {
    position: fixed;
  }
}

.modal .content {
  position: absolute;
  width: 550px;
  height: 475px;
  left: 50%;
  top: 50%;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  margin-left: -275px;
  margin-top: -225px;
  z-index: 100;
  color: initial;
}

.modal .content .header {
  background: #d3d3d3;
  padding: 15px;
  position: relative;
  height: auto;
  line-height: 22px;
}

.modal .content .header h3 {
  display: inline;
  margin: 0;
  padding: 0;
}

.modal .content .header .close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 6px;
  top: 10px;
  padding: 4px 10px;
}

.modal .content .body {
  height: 364px;
  overflow: auto;
  padding: 15px;
  p {
    margin: 0;
  }
}

.modal .content input[type='checkbox'] {
  bottom: 1px;
  position: relative;
  vertical-align: middle;
  width: auto;
  margin: 3px 3px 3px 4px;
}

.modal .content {
  .btn-group {
    display: flex;
    align-items: center;
  }

  input,
  textarea,
  select {
    border: 1px solid gray;
    border-radius: 2px;
  }

  input.color {
    padding: 0 5px;
    margin-right: 10px;
  }

  textarea {
    padding: 5px;
  }

  .form-group label {
    &:not(:last-child) {
      margin-right: 15px;
    }

    input:not([type='checkbox']),
    select {
      margin-left: 5px;
      padding-left: 5px;
    }

    input[type='checkbox'] {
      margin-right: 5px;
    }
  }

  .export-image {
    max-width: 100%;
    max-height: 250px;
  }
}

.modal .content input[type='range'] {
  margin: 0 5px;
}

.modal .content label {
  font-size: 14px;
}

.modal .content .footer {
  border-top: 1px solid #d3d3d3;
  padding: 15px;
  text-align: right;
}

@media (max-width: 500px), (max-height: 500px) {
  .modal .content {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    overflow: auto;
    position: absolute;
  }
}

.modal.is-presets {
  font-family: Lato, Verdana, sans-serif;

  .content {
    .header {
      font-size: 14px;
      h3 {
        font-weight: normal;
      }
    }

    .body {
      padding-top: 0;

      h2 {
        font-size: 16px;
        font-weight: normal;
        margin-top: 14px;
        margin-bottom: 8px;
      }

      a {
        color: #2563eb;
        background: url('/img/bubbles/presets_link.png') no-repeat 0 5px;
        line-height: 24px;
        padding-left: 14px;

        @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
          background-image: url('/img/bubbles/presets_link_2x.png');
          background-size: 5px 9px;
        }

        &:hover,
        &:active,
        &:focus {
          color: #60a5fa;
        }
      }
    }
  }
}

.modal.is-dark {
  .content {
    .header {
      background: #4a505f;
      color: #fff;
    }

    .body {
      background: #363a46;

      h2 {
        color: #fff;
      }
      a {
        color: #5faaf4;

        &:hover,
        &:active,
        &:focus {
          color: #fff;
        }
      }
    }
  }
}

.modal.is-presets {
  .content {
    .body {
      height: 422px;
    }
  }
}
