#map #hover {
  @apply font-bold;
  border: 4px solid #25262b;
  background: #fff;
  position: fixed;
  min-width: 300px;
  overflow: hidden;
  z-index: 10000;
  font-size: 14px;

  h3 {
    font-size: 13.5px;
    color: #25262b;
    margin: 0;
    line-height: 30px;
    padding: 0 10px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  table {
    width: 100.1%;

    tr {
      border-bottom: 2px solid #e3e4e9;
      height: 30px;
    }

    tr:last-child {
      border-bottom: none;
    }

    .ticker {
      padding-left: 10px;
    }

    .price {
      text-align: right;
      padding-left: 4px;
      padding-right: 4px;
    }

    .change {
      text-align: right;
      padding-right: 10px;
    }

    .hovered {
      color: #fff;
      font-size: 21px;
      border-bottom: none;
      height: 21px;

      td {
        padding-top: 14px;
        line-height: 18px;
        height: 40px;
      }

      &.is-description {
        td {
          height: auto;
        }

        .description,
        .change {
          font-size: 12px;
          font-weight: normal;
          line-height: 12px;
          padding-bottom: 10px;
          padding-left: 10px;
          padding-top: 6px;
        }

        .change {
          text-align: right;
        }
      }
      &.is-empty,
      &.is-empty td {
        height: 12px;
        padding: 0;
      }
    }

    &.is-small {
      tr {
        height: 23px;
      }
    }
  }

  .sparkline {
    vertical-align: middle;

    path {
      fill: none;
      stroke: #000;
      stroke-width: 0.5px;
    }

    &.white {
      path {
        stroke: #fff;
        stroke-width: 1.5px;
      }
    }
  }
}
