.help {
  color: #575e72;
  font-size: 16px;
  line-height: 1.75;

  h1 {
    font-size: 30px;
    margin: 0.67em 0 10px;
  }

  h2 {
    color: var(--link-blue);
    font-size: 20px;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
    line-height: 1.75;
    margin: 10px 0;

    &.is-larger {
      font-size: 16px;
      line-height: 1.75;
    }
  }

  .help-small {
    font-size: 11px;
    line-height: 2;
    color: #9b9b9b;

    a {
      color: var(--link-blue);
    }
  }
}

.help-menu {
  box-shadow: 0 3px 6px -3px rgba(202, 202, 202, 0.5);
  padding: 0;

  body.is-header-sticky & {
    position: sticky;
    top: 126px;
    background-color: #fff;
    z-index: 20;
  }

  body.is-header-sticky.is-elite & {
    top: 76px;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    display: inline-block;
    text-align: center;
    margin-right: 17px;

    a {
      color: var(--link-blue);
      display: block;
      border-bottom: 3px solid #fff;
      font-family: Lato;
      font-size: 13px;
      font-weight: bold;
      line-height: 13px;
      padding: 18px 0 15px 0;
    }

    &:hover,
    &.is-active {
      a {
        border-bottom-color: #575e72;
        color: #575e72;
      }
    }
  }
}

.help-submenu {
  float: left;
  margin: 16px 0;
  margin-right: 53px;
  width: 247px;
  flex: none;

  ul {
    list-style: none;
    padding: 0;
    position: sticky;
    top: 10px;

    body.is-header-sticky & {
      top: 191px;
    }

    body.is-header-sticky.is-elite & {
      top: 141px;
    }
  }

  li {
    a {
      background: center left no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAMFBMVEUAAABPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPT14Q/NAAAAD3RSTlMAd0Qzu+6ZEd3MiGZVqiLtOtI3AAAA3klEQVQY02WOvWpCURCEJ94QQxKSXAgknWKVdOcN0oS0IVgINvoG2tpa2txGsNTC3lI7BR9A30Cxt/DniFeEcVZBBD/Y3ZmFHRZXzMLhWfdI5qc48knjH8ajVF1VhOhzPUDQ4Ub6ueAHGkGTr8AtV0ACqDAHfB83RQRcAJ0tZHRQioG/HTKZL2WNWAb3NxQbvDANLmVE68J4ZyZaBGH4waxiHUprS/MOKW/fpGWySEaKv2cDeHC449KejssQKY7VJ6yqz+ltVyFr4RuVJ5ITGrGD8RRJ+3ecSLR/frsQB7uJc6oJRfbbAAAAAElFTkSuQmCC);
      background-size: 12px 12px;
      border-bottom: solid 1px #eae4e4;
      color: var(--link-blue);
      display: block;
      line-height: 42px;
      padding-left: 25px;
    }

    &:hover,
    &.is-active {
      a {
        font-weight: bold;
      }
    }

    &:last-child {
      a {
        border: none;
      }
    }
  }
}

.help-card,
.help-tab,
.help-hlite {
  background-color: #f5f5f5;
  border-left: 3px solid #575e72;
  padding: 10px 20px;

  ul {
    padding: 0;
    margin: 15px 0;
    list-style-type: disc;
    list-style-position: inside;
  }
}

.help-tab {
  background-color: #f5f5f5;
  padding: 17px 20px;
  font-size: 16px;

  ul {
    padding: 0;
    list-style-position: inside;
    font-size: 16px;
  }
}

.help-hlite {
  border-left-color: #fee500;

  hr {
    background: #eeeaea;
    border: none;
    font-size: 0px;
    height: 1px;
    margin: 15px 0;
  }
}

.help-technical {
  display: flex;
}

.help-technical-content {
  max-width: 792px;
}

.help-up,
.help-down,
.help-neutral {
  background-position: 18px 20px;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border-left: 4px solid;
  font-size: 16px;
  line-height: 1.75;
  list-style: none;
  padding: 13px 42px;
}

.help-hlite {
  p:first-child {
    margin-top: 0px;
  }

  .help-up,
  .help-down,
  .help-neutral {
    border: none;
    margin-left: -20px;
    margin-right: -20px;

    &:first-child {
      margin-top: -10px;
    }

    &:last-child {
      margin-bottom: -10px;
    }
  }
}

.help-up {
  background-color: #eff7ef;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAaVBMVEUAAAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAAAiAD8kPxfAAAAInRSTlMA+eqmIgeBLMNBmQ089NPPyIga8OibZ1dWOCuTdWloULq5NDcBRwAAAS1JREFUOMuFk9uWgyAMReONgtpWbe19pjP5/4/siRUD+kCWD5JsSHIIpNZc6y7Psryrbw1trRz3vNh+LFfhqs84sqyvou1HcR6MG6pqcOYgq2NwyO4Mh23V4Swc592yP8eRF4rskoEo5/w4/9TSytoTsnzr6LH/Prsf1j7m3zvO6KcE+Pn1xSBZ7lP/YKMkGVFfEA8IVFpDP+jTajwkWijW0BX9azwmoMeNamaj8RM+JYzk6JjdVL/ETcFcGCGmXhxzR/APsviTOAlAQvyLbwBJaHKSo5D4FxCimCREowvQvN7kAXq/Gg/4FGIKwHwKX+QG8EVqmzGgbYpQW0CFUqljQKXWy1IguKzVddOT+Unhda8HprS2jAYmPXLpodWxd9uxTz+c9NNLP97k8/8AzLkp9XGPEL4AAAAASUVORK5CYII=');
  border-left-color: #018901;
  color: #018901;
}

.help-down {
  background-color: #f9efef;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAbFBMVEUAAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAACqAADyKGEEAAAAI3RSTlMA9+ulLJmB8QjDIyHo0s/Ih0IZDDxWPgaVamg4ybqqdVhQEfzKLa8AAAFASURBVDjLhZPXloMwDERtgmkhoScE0vX//7hj2DFLO6vDg9FcW8Wymqxq2izVOs3aplJrO5pQnIXmuJCjXMvMdB7Ntp8FdgqKMorKIjgJ7PznED+FI75MjksMR+rzN4Gub/OYHUJ6ybi+4vywVgurQ0QZ88ixn/oxjpPfZY8z8sGHhTu/FWm5vmGjpQ3yU7SDyEHRkKlB/xDrsg3UyK1SDepX24BCPxobIdgDAhsjEyn2gEIkU55IuQeUaJZCkUM7vq/PBHxe36GFKNQBT+RCALGfBFyIh4AYAejyYAiXpO9ZwgJW93wmOZbpiBDfqLNMNoqECHU2iq0mQZ2t5mWRoM7L4nVPxKR39ro5MD2JLPOXA6OizZHrOXIc2m5raHfHvubYrx/O9eoeTvL/09t/vNokam3V29w9rb27eVeT9wcupyuxtfG+sAAAAABJRU5ErkJggg==');
  border-left-color: #b21a1a;
  color: #b21a1a;
}

.help-neutral {
  background-color: #f5f5f5;
  border-left-color: #575e72;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAYFBMVEUAAABXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnJXXnLP6u9CAAAAH3RSTlMAmfnrItFACIUsyMN+DfRWORkB52mYLfCNm7mpZ1Mp9vpRyQAAAPxJREFUOMuFU1kWgyAMZLEoiLW22mo37n/LDtVncHvJjxiSzGRIBFlVd04rpV1XV2JrmTyH2c5DtrrOexUWpop8kX6KzpM3TZ43xo9/SRF7g6M05DAlHDc75+Netde05PUNSJ1N+Kj4uKxJXx5AGXkUyKd7ikCN4g+AQ0tivJ7f6dgiMYJI8CN8D+j7xANMJVKgT8L/EyjCQLFK1OCSQhdJBNjXogvBi4MIHzEcIWwigOGEDqGJvfRyNh0joowNvgJNRjnKsDIXJUSjbAALcUTSziTlbpuW2twVypJQu1LbROq9x7L0WAP73NzAsCPHDS079vzi8KvHLy+7/j8JUybGNSgVhgAAAABJRU5ErkJggg==');
  color: #62697d;
}
