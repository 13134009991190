.faq_section {
  h1 {
    color: #575e72;
    font-size: 18px;
    margin-bottom: 9px;
    margin-top: 18px;
  }
}

.faq_question {
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  margin-bottom: 4px;
  transition: all ease 0.4s;
  &.is-open {
    background: #fff;
    border-color: #e2e4e9;
    h2 {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAN9JREFUeNpsUrERgzAMxNRpWAFGICOQOhWpUpMRYIWMAHWqUKWGFRghrOAmA+Tf97pzfPGd7m3pX0ISLkvO+fEpADVsf10Pexp3EbEB9LAmilMwQDj/CEDuACPMwyYhK3XCO0QDubkyk7zBKgU2YSV/D14bBMrCcwHJq9oCHPnG/aSKvQlYYWWD0afxdJFo1iCCoEib1H0S0fxhgrkeJR3ItuqbM4111b1W3FNAZ2lN/dkLya1Vc1rUW/EbZ67Jbcr8VOzIPl2UZVE/u8w27i1RumlbVGOTU5Up/kW+AgwAW8VkR+djIkIAAAAASUVORK5CYII=');

      @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAMFBMVEUAAABPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPT14Q/NAAAAD3RSTlMAu0R3mWYR7jPdzIhVIqr2e9FzAAAA3ElEQVQY02WOwUoCURiFv6KRCZIQgpYN7doVQlt7A3dBtGjWbYMIWuQbBG1bDG3aik+gS3eKDyD6BDoqjI7C8f53xI0HLnznHs7h50CDanfPT5I++wXfyPTmOXD0594DwIvSDmFbC6D8lXWAsKlbONISnuFV7/DtfgJXCDWH9srVrFDP4WpN4Ld6itHGBXLRuSI0Y3yptNIvjC1O8Uky98ZmG9TTnallNht5U0oWcKp/b06sHCiPy9cRNY2AiX6AobIYu1C/1TvpA6A0kSlvYDpLHGf3FDpuVS4eDbaxTXesv0uO7AAAAABJRU5ErkJggg==');
        background-size: 12px 12px;
      }
    }
  }
  h2 {
    background: no-repeat 14px center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMtJREFUeNpckc0RgjAQhTFDA7SAJcQS4OxJS5ASoAQtwZw9YQnQAiWYEuRiAWZnvjhPdubNhv17+5bd8fEpNlYl+IQI/szJu0mYEt74F7hoQ4m34D1hTbjhjelE3Bi73NAQXBJaKc7NVwZaPjihPFO0ZRzwvTLMFPaip+FtqwRydQm9WQ29mjE9hbl2nK4SDS0T8/SZYWaLI+DBDKKs4mGy+OpE1MgZC64T0DESG7LolTUmklHW9OQ7Vv79afvYMyXKhYzpIJqKrwADACZxO7QoRcUkAAAAAElFTkSuQmCC');
    color: var(--link-blue);
    cursor: pointer;
    font-size: 13px;
    padding-left: 33px;
    margin: 0;
    line-height: 40px;
    font-weight: normal;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAMFBMVEUAAABPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPT14Q/NAAAAD3RSTlMAd0Qzu+6ZEd3MiGZVqiLtOtI3AAAA3klEQVQY02WOvWpCURCEJ94QQxKSXAgknWKVdOcN0oS0IVgINvoG2tpa2txGsNTC3lI7BR9A30Cxt/DniFeEcVZBBD/Y3ZmFHRZXzMLhWfdI5qc48knjH8ajVF1VhOhzPUDQ4Ub6ueAHGkGTr8AtV0ACqDAHfB83RQRcAJ0tZHRQioG/HTKZL2WNWAb3NxQbvDANLmVE68J4ZyZaBGH4waxiHUprS/MOKW/fpGWySEaKv2cDeHC449KejssQKY7VJ6yqz+ltVyFr4RuVJ5ITGrGD8RRJ+3ecSLR/frsQB7uJc6oJRfbbAAAAAElFTkSuQmCC');
      background-size: 12px 12px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.faq_answer {
  transition: height 400ms;
  overflow: hidden;
  height: 0px;
  padding: 0 14px;
  p {
    color: #575e72;
    font-size: 13px;
    line-height: 18px;
    margin: 10px 0;
    &:first-child {
      margin-top: 0;
    }
  }
}

.faq_well {
  background: #f5f5f5;
  margin-top: 20px;
  padding: 10px 20px;
  text-align: center;
  h1 {
    color: #575e72;
    font-size: 18px;
    font-weight: normal;
    margin: 0.67em 0;
  }
  p {
    color: #575e72;
    font-size: 13px;
    margin: 10px;
  }
}
