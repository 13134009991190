body.is-quote {
  .screener_snapshot-table-body {
    /* Keep in sync #stock-detail-etf-ellipsis */
    /* The constants are best effort, but may still cause some overflow */
    &.is-overflowing {
      td {
        width: auto;
        &.is-longer {
          width: 13%;
          max-width: 6.5vw;
          text-overflow: ellipsis;
          overflow: hidden;

          &.is-smaller-than-td {
            width: auto;
            max-width: none;
          }
        }
      }
    }
  }
}

.quote-statements-elite-subscribe {
  background: #fff;
  border-width: 1px 1px 0 0;
  border-color: #d3d3d3;
  border-style: solid;
  text-align: center;
  font-family: Verdana;
  max-width: 400px;
}

.quote-statements-elite-subscribe h2,
.quote-statements-elite-tooltip_wrapper h2 {
  font-size: 13px;
  margin-bottom: 0;
}

.quote-statements-elite-subscribe p,
.quote-statements-elite-tooltip_wrapper p {
  font-size: 11px;
  padding: 0 10%;
}

.quote-statements-elite-subscribe a,
.quote-statements-elite-tooltip_wrapper a {
  font-size: 13px;
}

.quote-statements-elite-tooltip {
  background: url('/gfx/quote/blurred_chart.jpg') no-repeat;
  background-size: 400px 310px;
  width: 400px;
  height: 310px;
  position: relative;
  white-space: normal;
}

.quote-statements-elite-tooltip_wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 0 30px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
}

.quote-statements-elite-tooltip_wrapper p {
  padding: 0;
}

.quote_statements-footer {
  font-family: Verdana, Arial, Tahoma;
  font-size: 11px;
}

.table-perf {
  th,
  td {
    border: 1px solid #d3d3d3;
  }
}

.quote_perf-tickers-input {
  font-family: Verdana;
  font-size: 12px;
  width: 100%;
}

.quote_perf-table-heading {
  font-family: Verdana;
  font-size: 14px;
  margin-top: 0;
}

.news-table {
  td {
    @apply px-1;

    &:first-child {
      @apply whitespace-nowrap;
    }
  }
}

.multi-ticker-chart-container {
  width: fit-content;
  border: 1px solid #f3f3f3;
}

#chart {
  margin-bottom: 16px;
}
