.elite-header {
  background: #2e3447 url(/img/elite/header.png) bottom center no-repeat;
  border-bottom: 5px solid #6fb2f5;
  color: #fff;
  overflow: hidden;
  height: 450px;
  text-align: center;
  position: relative;

  &.is-small {
    height: auto;
    background-position: center 105px;
  }

  &.is-payment {
    background-image: url(/img/elite/bg.png);
    background-position: top center;

    .elite-payment-methods {
      padding: 0;
    }
  }

  h1 {
    font-size: 36px;
    line-height: 36px;
    margin-top: 86px;
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin: 25px auto 45px auto;
    width: 630px;
  }

  .elite-button-monthly {
    width: 280px;
    height: 80px;
  }

  .elite-scroll-down {
    display: block;
    position: absolute;
    width: 174px;
    height: 44px;
    bottom: 39px;
    left: 50%;
    margin-left: -87px;
  }
}

.elite-features {
  background: #f5f5f5;
  overflow: hidden;
  text-align: center;

  h2 {
    color: #3e4250;
    font-size: 30px;
    font-weight: 700;
    line-height: 75px;
    margin-top: 30px;
    margin-bottom: 0;
  }

  a {
    color: #5fa4e8;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &.is-premium {
    background: transparent;

    .elite-comparison {
      margin: 10px 0 30px 0;
    }

    .elite-features-table-wrapper {
      margin: 0;
    }
  }
}

.elite-boxes {
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  width: 990px;
}

.elite-box {
  background: #fff
    url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGBAMAAAAI3hL1AAAAFVBMVEX////DxMn6+/uys7n5+frBwsfHyMydsX66AAAALElEQVQI12MQU2BgSmRIFmJQNGNQNVQSDmJgCRY0dWBgcDUOYWBgYBF0YAAAT5IEQfYqcBsAAAAASUVORK5CYII=')
    155px 191px no-repeat;
  border-top: 4px solid #fff;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
  height: 220px;
  width: 320px;
  color: #575e72;
  font-size: 15px;
  font-style: italic;
  vertical-align: top;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMBAMAAACQIoDIAAAAHlBMVEUAAAA+QlA+QlA+QlA+QlA+QlA+QlA+QlA+QlA+QlBIeKsSAAAACnRSTlMAZWEfGBZVUk8q1xftBwAAAFhJREFUCNclyD0OQGAQhOFJcIBZEvUmFDoaNU4gKr0TuIMTOPG3P1NM3jz4P8TuCSqbV62Cl4PnwR47jQ3ZoaKxIWdgoZyNsgWcxyswODA5MNkxOTC3Pv4F5V8JXVHL4CAAAAAASUVORK5CYII=');
    background-size: 10px 6px;
  }

  /* transition: margin 0.5s ease; */
  h3 {
    color: var(--link-blue);
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .elite-box_header {
    margin-top: 116px;
    height: 100px;
    line-height: 18px;
  }

  .elite-box_icon {
    background: url('/img/elite/box_icons.png') no-repeat top left;
    position: absolute;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('/img/elite/box_icons_2x.png');
      background-size: 695px 76px;
    }
  }

  &.is-alerts .elite-box_icon {
    background-position: -615px 0;
    width: 80px;
    height: 65px;
    margin-left: 120px;
    margin-top: 38px;
  }

  &.is-backtests .elite-box_icon {
    background-position: 0 0;
    width: 112px;
    height: 76px;
    margin-left: 96px;
    margin-top: 25px;
  }

  &.is-charts .elite-box_icon {
    background-position: -169px 0;
    width: 206px;
    height: 53px;
    margin-left: 63px;
    margin-top: 39px;
  }

  &.is-correlations .elite-box_icon {
    background-position: -375px 0;
    width: 180px;
    height: 68px;
    margin-left: 70px;
    margin-top: 32px;
  }

  &.is-realtime .elite-box_icon {
    background-position: -112px 0;
    width: 57px;
    height: 57px;
    margin-left: 130px;
    margin-top: 37px;
  }

  &.is-screener .elite-box_icon {
    background-position: -555px 0;
    width: 60px;
    height: 60px;
    margin-left: 130px;
    margin-top: 36px;
  }

  .elite-box_image {
    float: right;
    height: 280px;
    margin-right: 60px;
    text-align: center;
    width: 600px;

    img {
      vertical-align: middle;
    }
  }

  .elite-box_content {
    background: #fff;
    color: #3e4250;
    cursor: auto;
    display: block;
    font-size: 16px;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    position: absolute;
    /* transition: height 0.5s ease; */
    font-style: normal;
    width: 980px;

    .pull-left {
      margin-left: 60px;
      margin-right: 60px;
      text-align: left;
      width: 200px;
    }

    p {
      margin: 15px 0;
    }

    ul {
      padding-left: 0;
      list-style-position: inside;
      list-style-type: disc;
      margin: 15px 0;
    }
  }

  .elite-box_prev,
  .elite-box_next,
  .elite-box_close {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAQCAMAAAA25D/gAAAAUVBMVEUAAABPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPSVLdtGAAAAGnRSTlMAZpv44I0Pz+rk14ByKCEbFgPtxqiTkDYzMGx8ChoAAACrSURBVBjTbZBZDsMwCEQxFMfZ9437H7SkCSGt+j5sYCQ0DDBhhBMMoAS82ojEQCLjpQcJ53OqowgBi0jW3fqtdpkKDD3qV7Smm9oWOsZeq0WLV/MtNy8dLvCh1DKvn8vrXEclXFTapM2tbUkHFdyEYz9kdtixOcCvbLjsy4fV+3Ww5WYt7fBgT26Nzbhh1lmLHu1sww/XWNhCczw2BrLIHQ+dYKYpwl/iRPMbglwNeinnNxYAAAAASUVORK5CYII=');
    height: 16px;
    opacity: 0.5;
    position: absolute;
    width: 10px;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAAgCAMAAAB5NuugAAAAbFBMVEUAAABPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRPpPRMJkixAAAAI3RSTlMA+ZhmEe7MMwPz6gkGvayhW6WLgxfitndxTrGonHxTRTxqSBUUl1kAAAF9SURBVDjLlZTtsoIgEIZfUU+EpJlflVYW93+PJ5RtCJCZnl/MyhMzu++GrMq7qoUL+8tApOZs0VZdXmWo1RuxwzepUtqg8wHf7IS2auyVZj+7L2ub3Nz57dlIEGo9vEJ22H2trhIY1EpyD9kh954YZQA/kH30bXb33SO5PQeKXhmevu27T0VugTeyVIbBsfUHxx3obimxwCuqNLDZ5e8Sg01DNysOglGNOW5Hpcg9nKg6cdtNsz/rIp/o1glf3Kg+SssFLFuOdOcGhzN9uS5dzFZX26aRxTUwFSKl+R0KrRj3cyw+eTD1jekzIF0EsnXUmJMll4uxBQCmXbLLDBDGvSDMY09yAFqgB4hw4CcEmFTs6aPdMJ8itEBEqqIu2Zp0e8wSG8jPoM8bAas5NuG1HTE/2owjAmeBcLs1H/8VxNeZiC20LJ0+xDnbfyWyD06AiMy0l2iigY+mqUEXS118gToksbzHNyjB0q58xk/M+dIytHUixhY/0o4iqdt/hPROQu1UKEUAAAAASUVORK5CYII=');
      background-size: 30px 16px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .elite-box_prev {
    background-position: -20px 0;
    left: 24px;
    top: 163px;
  }

  .elite-box_next {
    background-position: 0 0;
    left: 946px;
    top: 163px;
  }

  .elite-box_close {
    background-position: -11px 0;
    height: 8px;
    width: 8px;
    left: 947px;
    top: 21px;
  }

  &:hover {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAGBAMAAAAI3hL1AAAAG1BMVEUAAABfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOi9t7UkAAAACHRSTlMAxw/OEr23FejhOQgAAAAsSURBVAjXYxBTYGBKZIgQYlBsZVBuVJIwYmCxEGx2YGBw7jBhYGBgFyxgAABfqQVRfSWYGgAAAABJRU5ErkJggg==');
    border-color: #5fa4e8;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMBAMAAACQIoDIAAAALVBMVEUAAABfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOhfpOhyzbvdAAAADnRSTlMA+fU8Su5TONXMyGlOP7CBXkUAAABqSURBVAjXY9i0mAEMqrQZ4h5dALF49Z4yzHunDGIavXvJYPjukQEDA7PeO2EGFjmgMFDwoQMDg+O7RwXseu9EGBhAwuGlIEEgOPjuadw7GbCJbHLv3j1MgJie+O6dGJCCCAMFocCjBUQCAN6vIx6kn1ibAAAAAElFTkSuQmCC');
      background-size: 10px 6px;
    }
  }

  &.is-expanded {
    background: #fff;
    border-color: #5fa4e8;
    cursor: auto;
    height: 230px;
    margin-bottom: 345px;

    .elite-box_content {
      height: 340px;
      left: 5px;
      margin-top: 10px;
      padding-top: 30px;
    }
  }

  @media (min--moz-device-pixel-ratio: 1.3),
    (-o-min-device-pixel-ratio: 2.6/2),
    (-webkit-min-device-pixel-ratio: 1.3),
    (min-device-pixel-ratio: 1.3),
    (min-resolution: 1.3dppx) {
    &.is-expanded {
      background: #fff;
    }
  }
}

.elite-ads {
  color: #3e4250;
  font-size: 30px;
  font-style: italic;
  line-height: 140px;
}

.elite-comparison {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 0;
}

.elite-users {
  padding-bottom: 65px;
  text-align: center;

  h2 {
    color: #3e4250;
    font-size: 30px;
    line-height: 36px;
    margin: 0 auto;
    padding: 65px 0;
    width: 700px;
  }

  img {
    margin: auto;
  }
}

.elite-subscription {
  border-top: 5px solid #5b9fe3;
  background: #383c4a;
  color: #fff;
  text-align: center;

  h2 {
    font-size: 30px;
    line-height: 30px;
    margin-top: 58px;
    margin-bottom: 30px;
  }

  p {
    color: white;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 70px;
  }

  .footer {
    color: #c0c3cc;
    font-size: 13px;
    line-height: 18px;
    padding-bottom: 80px;
  }

  .footer_dot {
    color: #6e778e;
  }

  a.tab-link {
    color: var(--link-blue);
    font-size: 13px;
  }
}

.elite-button-monthly {
  background-color: #4ea4f4;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: inline-block;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 22px;
  font-size: 16px;
  padding: 19px 0;
  width: 220px;
  height: 84px;
  outline: 0;

  b {
    display: block;
    font-size: 18px;
    font-weight: 700;
  }

  &:hover,
  &.is-active {
    background-color: #2c94f4;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
  }

  &.is-not-active {
    opacity: 0.5;
  }
}

.elite-button-yearly {
  background-color: #9f82f3;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  line-height: 22px;
  font-size: 16px;
  color: #fff;
  display: inline-block;
  padding: 19px 0;
  width: 220px;
  height: 84px;
  position: relative;
  margin-left: 8px;
  outline: 0;

  b {
    display: block;
    font-size: 18px;
    font-weight: 700;
  }

  &:hover,
  &.is-active {
    background-color: #8e6cf3;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
  }

  &.is-not-active {
    opacity: 0.5;
  }
}

.elite-button_tooltip {
  position: absolute;
  color: #474b59;
  background: #ffe13e;
  height: 26px;
  line-height: 26px;
  border-radius: 0;
  top: -39px;
  right: -45px;
  font-size: 11px;
  padding-left: 10px;
  padding-right: 10px;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 16px;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid #ffe13e;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
}

.elite-cancel {
  opacity: 0.5;
  font-size: 16px;
  margin: 50px 0 80px 0;
}

.elite-features-table-wrapper {
  height: 0;
  margin-bottom: 50px;
  overflow: hidden;
  transform: transale3d(0, 0, 0);
  transition: height 0.5s ease;
}

.elite-features-table {
  background: #fff;
  color: #62697d;
  font-size: 14px;
  margin: 0 auto;
  width: 880px;

  thead {
    th {
      background: #62697d;
      color: #fff;
      font-size: 16px;
      line-height: 37px;
      padding: 0 10px;
    }
  }

  td {
    border: 1px solid #e2e4e9;
    line-height: 20px;
    padding: 7px 10px;
    text-align: center;
  }

  tr td:nth-child(1),
  tr th:nth-child(1) {
    text-align: left;
  }

  tr td:nth-child(4) {
    font-weight: bold;
  }

  tr:nth-child(even) {
    background: #fafafa;
  }

  &.has-hidden-free {
    tr td:nth-child(2),
    tr th:nth-child(2) {
      display: none;
    }
  }
}

.elite-paypal-cancel {
  border: 2px solid #f3f5f8;
  color: #62697d;
  font-size: 16px;
  line-height: 24px;
  margin: 30px auto;
  padding: 14px 0;
  text-align: center;
  width: 990px;
}

.elite-premium-boxes {
  margin: 0 auto;
  width: 990px;

  h2 {
    color: #3e4250;
    font-size: 36px;
    line-height: 36px;
    margin: 49px 0 0 0;
    text-align: center;
  }
}

.elite-premium-box {
  border-top: 5px solid #f3f5f8;
  min-height: 390px;

  h3 {
    font-size: 24px;
    line-height: 22px;
    margin: 143px 0 12px 0;
    text-transform: uppercase;
  }

  p {
    color: #62697d;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin: 12px 0 0 0;
    width: 410px;
  }

  ul {
    color: #62697d;
    font-size: 16px;
    font-style: italic;
    line-height: 26px;
    margin: 1px 0 0 0;
    padding-left: 0;
    list-style-position: inside;
    list-style-type: disc;
  }

  a {
    color: #5fa4e8;

    &:hover {
      text-decoration: underline;
    }
  }

  .elite-premium-box_content {
    width: 470px;
  }

  .elite-premium-box_image {
    padding-top: 48px;
    width: 495px;
  }

  &.is-realtime,
  &.is-backtests,
  &.is-screener,
  &.is-customization {
    .elite-premium-box_content {
      float: right;
    }

    .elite-premium-box_image {
      float: left;
    }
  }

  &.is-charts,
  &.is-correlations,
  &.is-alerts,
  &.is-other {
    .elite-premium-box_content {
      float: left;
    }

    .elite-premium-box_image {
      float: right;
    }
  }

  .elite-premium-box_icon {
    background: url('/img/elite/box_icons.png') no-repeat top left;
    position: absolute;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('/img/elite/box_icons_2x.png');
      background-size: 695px 76px;
    }
  }

  &.is-alerts .elite-premium-box_icon {
    background-position: -615px 0;
    width: 80px;
    height: 65px;
    margin-top: 46px;
  }

  &.is-backtests .elite-premium-box_icon {
    background-position: 0 0;
    width: 112px;
    height: 76px;
    margin-top: 40px;
  }

  &.is-charts .elite-premium-box_icon {
    background-position: -169px 0;
    width: 206px;
    height: 53px;
    margin-top: 43px;
  }

  &.is-correlations .elite-premium-box_icon {
    background-position: -375px 0;
    width: 180px;
    height: 68px;
    margin-top: 43px;
  }

  &.is-realtime .elite-premium-box_icon {
    background-position: -112px 0;
    width: 57px;
    height: 57px;
    margin-top: 53px;
  }

  &.is-screener .elite-premium-box_icon {
    background-position: -555px 0;
    width: 60px;
    height: 60px;
    margin-top: 50px;
  }

  &.is-customization .elite-premium-box_icon {
    background-image: url('/img/elite/customization.png');
    width: 62px;
    height: 62px;
    margin-top: 51px;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('/img/elite/customization_2x.png');
      background-size: 62px 62px;
    }
  }

  &.is-other .elite-premium-box_icon {
    background-image: url('/img/elite/other.png');
    width: 82px;
    height: 20px;
    margin-top: 83px;

    @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
      background-image: url('/img/elite/other_2x.png');
      background-size: 82px 20px;
    }
  }
}

.elite-carousel {
  position: relative;
}

.elite-carousel_pages {
  position: absolute;
  text-align: center;
  top: 305px;
  width: 100%;
}

.elite-carousel_page {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAMBAMAAACKHmBGAAAALVBMVEX///+YevK3vMf4+frQ09q8wMu6pfafg/P08f7v6v309fajiPO/w827p/adgPPu4uvVAAAARklEQVQI12PAA5h9jhgwMHDc3tsA5CxVUopiYJguKFgJ5Dg5m6gwMCQKCooBOUEGzKoMDIWCgnLoHIQyFAMQRmdva8BjPwA4hxDcicGYWAAAAABJRU5ErkJggg==')
    no-repeat top left;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin: 0 2px;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAYCAMAAACLI47uAAAAS1BMVEX///+YevK3vMf9/f7GtPjGytO6vsr6+vudgPPX2uDW2N/09fbv6/3u6f3RxPm1oPaslPWoj/Tl5uvj5enf4ebLz9fDx9C+wszy8/UgIYzkAAAAoUlEQVQ4y92SWQ6FIAxFaQEZnGf3v9In+kCqCQl+er6ak5DS27IvURqJKE1595XoADpR3f1U4EExUd9oONAN0XzGwMwj30KgjTyzGGEuLyBCXH7DnXXkfFxdFeaogVCTBlK5SsnQgjagLRZEHM5y2MvF+x4IPfO4hP4jKZeU90DR2Q9efuk5tE0MTWJl7Ix1S8b6XJxNLC7vNDKPL/u8P8QPtMQGzpdyDLkAAAAASUVORK5CYII=');
    background-size: 24px 12px;
  }

  &:hover,
  &.is-active {
    background-position: top right;
  }
}

.elite-tip {
  background: #fffce5 url('/img/elite/tip.png') no-repeat left center;
  border: 1px solid #fee500;
  color: #3e4250;
  font-size: 16px;
  line-height: 20px;
  margin: 30px 0;
  padding: 18px 50px 18px 70px;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('/img/elite/tip_2x.png');
    background-size: 51px 31px;
  }
}

.elite-payment-methods {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  padding: 20px 0;
  margin-top: 15px;
  overflow: hidden;
  transition:
    height 1s,
    padding 1s;
}

.elite-header .elite-payment-methods {
  background: transparent;
}

button.elite-payment-method {
  display: inline-block;
  height: 92px;
  width: 200px;
  text-align: center;
  border: 1px solid #555555;
  border-radius: 4px;
  padding: 15px 0;
  background: #fff;
  color: #3e4250;
  margin-bottom: 10px;
  font-weight: bold;
  line-height: 1em;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background: #f2f2f2;
  }
}

.elite-payment-paypal {
  width: 200px;
  height: 55px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

.elite-payment-method:hover,
.elite-payment-paypal:hover {
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}

.cardtypes {
  margin-bottom: 10px;
}

.elite-header .elite-payment-method:hover {
  box-shadow: 0 1px 10px var(--link-blue) 40;
}

.elite-payment-method img {
  height: 35px;
  margin: 0 3px;
}

.elite-cancel-button {
  background: var(--link-blue);
  border: none;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  height: 28px;
  outline: none;
  width: 78px;

  &:hover,
  &:active,
  &:focus {
    background: #3798f3;
  }
}
