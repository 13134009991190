/* http://nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix {
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.fixed-width-large {
  margin: 0 auto;
  width: 980px;
}

.fixed-width-mid-large {
  margin: 0 auto;
  width: 792px;
}

.fixed-width-medium {
  margin: 0 auto;
  width: 650px;
}

.fixed-width-small {
  margin: 0 auto;
  width: 342px;
}

.box-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
