/*
  1. Class-only, no ids.
  2. js-* for elements referred from JS
  3. Module-based, with following syntax:
    .module-name {}
    .module-name_submodule {}
    .module-name.is-modifier {} (sometimes .with-modifier or .has-modifier)
    Cize: "_" je rezervovane na submoduly, "-" mozes pouzivat ako chces.
  4. .is-* cannot be styled separately. Adding .is-* class to an element should not alter element in any way, unless explicitly defined. Think of it as "&.is-modifier {} " is valid, ".is-modifier {}" is invalid.
  5. No more than 3-level nesting. Submodule should not be nested.
*/

.futures {
  --indices: #27399d;
  --energy: #7f35d4;
  --bonds: #74b9ff;
  --softs: #afaac2;
  --metals: #ecc100;
  --meats: #e999d8;
  --grains: #e28e00;
  --currencies: #aa8240;

  margin: 0 auto;
  max-width: 1282px;
  padding-top: 10px;

  /* Don't remove, keeps skyscraper ads below the content */
  position: relative;
  z-index: 10;

  .menu {
    background: #ebebeb;
    border-bottom: 1px solid #d9d9d9;
    position: relative;
    height: 31px;
    margin-bottom: 9px;

    .menu-pages {
      position: absolute;
      top: 0;
      left: 15px;

      margin: 0;
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        a {
          color: #5faaf4;
          display: block;
          font-weight: bold;
          font-size: 11px;
          line-height: 30px;
          padding: 0 5px 1px 5px;
          text-transform: uppercase;
          &:hover,
          &:active,
          &:focus {
            color: #3e424f;
          }
        }
        &.is-active {
          a {
            color: #3e424f;
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAfCAIAAACpqzA1AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABtJREFUeNpifv36DRMDAwM9MPPbL+zIfIAAAwB8oATrQEGXqQAAAABJRU5ErkJggg==')
              repeat-x;
          }
        }
      }
    }

    .menu-groups {
      margin: 0 auto;
      padding: 0;
      text-align: center;
      width: 70%;
      li {
        list-style: none;
        display: inline-block;
        a {
          border-radius: 20px;
          color: #3e424f;
          font-size: 11px;
          line-height: 30px;
          padding: 3px 8px 4px 8px;
          &:hover,
          &:active,
          &:focus {
            background: #fff;
          }
          .menu-groups_color {
            border-radius: 20px;
            display: inline-block;
            height: 6px;
            width: 6px;
            margin-right: 4px;
            margin-bottom: 1px;
            &.is-indices {
              background: var(--indices);
            }
            &.is-energy {
              background: var(--energy);
            }
            &.is-bonds {
              background: var(--bonds);
            }
            &.is-softs {
              background: var(--softs);
            }
            &.is-metals {
              background: var(--metals);
            }
            &.is-meats {
              background: var(--meats);
            }
            &.is-grains {
              background: var(--grains);
            }
            &.is-currencies {
              background: var(--currencies);
            }
          }
        }
        &.is-active {
          a {
            background: #62697d;
            color: #fff;
          }
        }
      }
    }

    .menu-timeframes {
      position: absolute;
      top: 0;
      right: 15px;

      margin: 0;
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        a {
          border-radius: 20px;
          color: #3e424f;
          font-size: 11px;
          line-height: 30px;
          padding: 3px 8px 4px 8px;
          &:hover,
          &:active,
          &:focus {
            background: #fff;
          }
        }
        &.is-active {
          a {
            background: #62697d;
            color: #fff;
          }
        }
      }

      &.menu-currencies {
        position: relative;
        display: flex;
      }
    }
  }

  .tile {
    color: #fff;
    cursor: pointer;
    font-size: 11px;
    float: left;
    margin: 1px;
    width: 156px;
    height: 85px;
    position: relative;

    .tile_header {
      background: #20379b;
      height: 14px;
      line-height: 14px;
      padding: 0 4px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: inherit;
        width: 9px;
        height: 9px;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        margin: -5px auto 0;
        border-bottom: 1px solid #fff;
        border-right: 1px solid #fff;
      }

      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: inherit;
        width: 100%;
        height: 1px;
        margin-top: -1px;
      }

      &.is-indices {
        background: var(--indices);
      }
      &.is-energy {
        background: var(--energy);
      }
      &.is-bonds {
        background: var(--bonds);
      }
      &.is-softs {
        background: var(--softs);
      }
      &.is-metals {
        background: var(--metals);
      }
      &.is-meats {
        background: var(--meats);
      }
      &.is-grains {
        background: var(--grains);
      }
      &.is-currencies {
        background: var(--currencies);
      }
    }

    .tile_label {
      font-weight: bold;
      position: relative;
      text-transform: uppercase;
    }

    .tile_change-pct,
    .tile_last-price {
      font-weight: bold;
      font-size: 14px;
      padding: 0 4px;
    }

    .tile_content {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.09) 0%, transparent 100%);
      height: 70px;
      margin-top: 1px;

      .sparkline {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .simple-tile {
    color: #fff;
    cursor: pointer;
    font-size: 11px;
    float: left;
    margin: 1px;
    width: 156px;
    height: 85px;
    position: relative;

    .tile_header {
      height: 14px;
      line-height: 14px;
      font-weight: bold;
      padding-left: 4px;

      &.is-indices {
        background: var(--indices);
      }
      &.is-energy {
        background: var(--energy);
      }
      &.is-bonds {
        background: var(--bonds);
      }
      &.is-softs {
        background: var(--softs);
      }
      &.is-metals {
        background: var(--metals);
      }
      &.is-meats {
        background: var(--meats);
      }
      &.is-grains {
        background: var(--grains);
      }
      &.is-currencies {
        background: var(--currencies);
      }
    }

    .tile_content {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.09) 0%, transparent 100%);
      height: 70px;
      margin-top: 1px;
      padding: 0 5px;
      font-weight: bold;
      overflow: hidden;
    }

    .tile_label {
      text-transform: uppercase;
      margin: 4px 0 2px 0;
      line-height: 11px;
    }

    .tile_last-price {
      font-size: 24px;
      width: 100%;
      line-height: 24px;
      margin: 2px 0;
    }

    .tile_change,
    .tile_high-low {
      color: rgba(255, 255, 255, 0.5);
      line-height: 12px;
      text-align: right;
    }

    .tile_high-low {
      div {
        padding: 0 2px;
        margin-left: -2px;
      }
    }

    .tile_high-label,
    .tile_low-label {
      /* font-family: Verdana, sans-serif; */
      /* font-size: 13px; */
      display: block;
      float: left;
      margin-right: 3px;
    }

    .tile_low-label {
      padding-right: 1px;
    }
  }

  .chart-menu {
    border: 1px solid #ebebec;
    border-top: none;
    min-height: 57px;
    margin: -9px 0 9px;

    .chart-menu_type {
      display: inline-block;
      margin: 4px 0 0 9px;
      padding: 0;
      width: 100px;
      li {
        list-style: none;
        a {
          color: #5faaf4;
          display: block;
          font-size: 12px;
          font-weight: bold;
          line-height: 24px;
          padding-left: 2px;
          &:hover,
          &:active,
          &:focus {
            color: #3e424f;
          }
        }
        &.is-active {
          a {
            color: #3e424f;
          }
        }
        &.is-static {
          border-bottom: 1px solid #ebebec;
        }
        .chart-menu_interactive-settings {
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAKlJREFUeNp0UNsNwjAMTKsuUEZghXQEGAFGyAxdpRmBjkBWyAD56QiwAj50lk4RWDrl4bN9vqG1FrpIhtmQDW9NTDyRvBh2w8a/KuSq5KchGg5phKIzCxbkRq1kMnT3wyeAfOsS6HKiJJU4g/zgI3Ap15plAjhxZAfXGn/IQBQ0GGhdEhcKi5O8r7g4+UVt/wJ7VHdjpda7EFbqzr3PWRbaOaWIpd/4CDAALLUt4tzAJJYAAAAASUVORK5CYII=')
            center center no-repeat;
          display: inline-block;
          margin-right: 4px;
          margin-top: -22px;
          float: right;
          height: 20px;
          width: 20px;
          &:hover,
          &:active,
          &:focus {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMBJREFUeNp0UcERAiEM5LQCW7gvz7MErwQs4WqgFSnhaMES5MuTrz/9+sPNzWYmOspMhsBu2GQZeu/Orpzzgu2ASCGEp8UGIYMg4Akg0qzVM2Ij475Y8g35hGiIkWTNpeCIgrYjULiPRtUWbQp7732QFgg2ykeePeIl97XWu7y8kuw4VOFgySgIZxJy5ouOfbsfLV2lFR1Q7LoYQIoXPUNptm486O2/JW4UdSPSkbMhRPadPnz++sGVKlFJut4CDAB4FFS44eB9MAAAAABJRU5ErkJggg==');
          }
        }
      }
    }
  }

  .chart-menu_charts {
    float: right;
    width: calc(100% - 109px); /* TODO not enough support */
    text-align: center;
    margin: 4px auto 0;
    .chart-menu_chart-preview {
      span {
        color: #62697d;
        display: block;
        font-size: 10px;
        font-weight: bold;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
      }
      border: 2px solid #fff;
      display: inline-block;
      margin: 0 8px;
      width: 92px;
      height: 46px;
      &:hover,
      &:active,
      &:focus {
        border: 2px solid #ebebec;
      }
      &.is-active {
        border: 2px solid #ffd614;
      }
      path {
        stroke: #000;
        fill: none;
        stroke-width: 0.5px;
      }
    }
  }

  .bar-chart {
    margin: 5px;
    h1 {
      text-transform: uppercase;
      color: #4c5261;
      font-size: 11px;
      padding-left: 100px;
      margin: 10px 0;
    }
    rect {
      stroke: white;
    }
    text {
      fill: white;
    }
    text.name {
      fill: #000;
    }
    .score.is-outside {
      color: #000;
    }
    line {
      stroke: #c1c1c1;
    }
    .rule {
      fill: #000;
    }
    .rect {
      color: #fff;
      cursor: default;
      text-align: right;
      line-height: 12px;
      transition: all 0.5s ease-in-out;
      .value {
        position: absolute;
        text-align: right;
        padding-right: 2px;
        font-size: 12px;
        font-weight: bold;
        width: 100%;
        text-shadow: 0.5px 0.9px 0 rgba(0, 0, 0, 0.25);
      }
      .value.is-outside {
        color: #000;
        text-align: left;
        text-shadow: none;
        width: 100px;
      }
      .label {
        color: #4c5261;
        font-size: 11px;
        left: -100px;
        padding-right: 6px;
        position: absolute;
        text-align: right;
        width: 100px;
      }
    }
    .rect.is-inactive {
      opacity: 0.5;
      transition: all 0.15s ease-in-out;
    }
    .rect:hover {
      cursor: pointer;
    }
    .bar-chart_tick {
      border-left: 1px dashed #cbced5;
      height: 100.5%;
      padding-bottom: 5px;
      width: 1px;
      div {
        width: 30px;
        text-align: center;
        margin: 0 auto 0 -15px;
        font-size: 11px;
        position: absolute;
        top: 100%;
        color: #4c5261;
        opacity: 0.5;
      }
    }
    .bar-chart_top {
      border-top: 1px dashed #cbced5;
      position: absolute;
      height: 1px;
      top: -2px;
    }
    .bar-chart_bottom {
      border-bottom: 1px dashed #cbced5;
      position: absolute;
      height: 1px;
      top: 100.5%;
    }
  }

  .timeframes-tooltip {
    position: absolute;
    color: #474b59;
    background: #ffe13e;
    height: 26px;
    line-height: 26px;
    border-radius: 0;
    top: -30px;
    right: 115px;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 26px;

    a {
      color: #474b59;
    }

    &:after {
      content: '';
      position: absolute;
      top: 100%;
      right: 12px;
      margin-left: -8px;
      width: 0;
      height: 0;
      border-top: 8px solid #ffe13e;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }

    .timeframes-tooltip_close {
      font-size: 15px;
      font-weight: bold;
      position: absolute;
      right: 8px;
    }
  }
}

#futures_charts,
#forex_charts,
#crypto_charts {
  .charts-gal-link {
    display: inline-block;
  }
}
