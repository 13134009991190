:root {
  --text-default: rgbstring(theme(colors.gray.800));
  --text-link: rgbstring(theme(colors.blue.500));
  --text-disabled: rgbstring(theme(colors.gray.400));
  --text-muted: rgbstring(theme(colors.gray.600));
  --text-muted-2: rgbstring(theme(colors.gray.500));
  --text-muted-3: rgbstring(theme(colors.gray.100));
  --text-positive: rgbstring(theme(colors.green.500));
  --text-negative: rgbstring(theme(colors.red.500));

  --bg-primary: rgbstring(theme(colors.white));
  --bg-secondary: rgbstring(theme(colors.gray.50));
  --bg-accent: rgbstring(theme(colors.blue.50));

  --border-primary: rgbstring(theme(colors.gray.100));
  --border-secondary: rgbstring(theme(colors.gray.100));
  --border-accent: rgbstring(theme(colors.blue.200));

  --bg-danger-5: theme(colors.red.400);
  --text-danger-5: theme(colors.red.900);
  --border-danger-5: theme(colors.red.900);

  --bg-danger-4: theme(colors.red.300);
  --text-danger-4: theme(colors.red.800);
  --border-danger-4: theme(colors.red.800);

  --bg-danger-3: theme(colors.red.200);
  --text-danger-3: theme(colors.red.700);
  --border-danger-3: theme(colors.red.700);

  --bg-danger-2: theme(colors.red.100);
  --text-danger-2: theme(colors.red.600);
  --border-danger-2: theme(colors.red.600);

  --bg-danger-1: theme(colors.red.50);
  --text-danger-1: theme(colors.red.500);
  --border-danger-1: theme(colors.red.500);

  --bg-neutral: theme(colors.gray.50);
  --text-neutral: theme(colors.gray.600);
  --border-neutral: theme(colors.gray.600);

  --bg-success-1: theme(colors.green.50);
  --text-success-1: theme(colors.green.500);
  --border-success-1: theme(colors.green.500);

  --bg-success-2: theme(colors.green.100);
  --text-success-2: theme(colors.green.600);
  --border-success-2: theme(colors.green.600);

  --bg-success-3: theme(colors.green.200);
  --text-success-3: theme(colors.green.700);
  --border-success-3: theme(colors.green.700);

  --bg-success-4: theme(colors.green.300);
  --text-success-4: theme(colors.green.800);
  --border-success-4: theme(colors.green.800);

  --bg-success-5: theme(colors.green.400);
  --text-success-5: theme(colors.green.900);
  --border-success-5: theme(colors.green.900);

  &.dark {
    --text-default: rgbstring(theme(colors.gray.50));
    --text-link: rgbstring(theme(colors.blue.300));
    --text-disabled: rgbstring(theme(colors.gray.400));
    --text-muted: rgbstring(theme(colors.gray.200));
    --text-muted-2: rgbstring(theme(colors.gray.300));
    --text-muted-3: rgbstring(theme(colors.gray.700));
    --text-positive: rgbstring(theme(colors.green.400));
    --text-negative: rgbstring(theme(colors.red.400));

    --bg-primary: rgbstring(theme(colors.gray.800));
    --bg-secondary: rgbstring(theme(colors.gray.900));
    --bg-accent: rgbstring(theme(colors.gray.700));

    --border-primary: rgbstring(theme(colors.gray.600));
    --border-secondary: rgbstring(theme(colors.gray.700));
    --border-accent: rgbstring(theme(colors.gray.500));

    --bg-danger-5: theme(colors.red.500);
    --text-danger-5: theme(colors.white);
    --border-danger-5: theme(colors.white);

    --bg-danger-4: color-mix(in srgb, theme(colors.red.500), theme(colors.gray.800) 35%);
    --text-danger-4: theme(colors.red.50);
    --border-danger-4: theme(colors.red.50);

    --bg-danger-3: color-mix(in srgb, theme(colors.red.500), theme(colors.gray.800) 50%);
    --text-danger-3: theme(colors.red.100);
    --border-danger-3: theme(colors.red.100);

    --bg-danger-2: color-mix(in srgb, theme(colors.red.500), theme(colors.gray.800) 65%);
    --text-danger-2: theme(colors.red.200);
    --border-danger-2: theme(colors.red.200);

    --bg-danger-1: color-mix(in srgb, theme(colors.red.500), theme(colors.gray.800) 80%);
    --text-danger-1: theme(colors.red.300);
    --border-danger-1: theme(colors.red.300);

    --bg-neutral: theme(colors.gray.750);
    --text-neutral: theme(colors.gray.300);
    --border-neutral: theme(colors.gray.300);

    --bg-success-1: color-mix(in srgb, theme(colors.green.500), theme(colors.gray.800) 80%);
    --text-success-1: theme(colors.green.300);
    --border-success-1: theme(colors.green.300);

    --bg-success-2: color-mix(in srgb, theme(colors.green.500), theme(colors.gray.800) 65%);
    --text-success-2: theme(colors.green.200);
    --border-success-2: theme(colors.green.200);

    --bg-success-3: color-mix(in srgb, theme(colors.green.500), theme(colors.gray.800) 50%);
    --text-success-3: theme(colors.green.100);
    --border-success-3: theme(colors.green.100);

    --bg-success-4: color-mix(in srgb, theme(colors.green.500), theme(colors.gray.800) 35%);
    --text-success-4: theme(colors.green.50);
    --border-success-4: theme(colors.green.50);

    --bg-success-5: theme(colors.green.500);
    --text-success-5: theme(colors.white);
    --border-success-5: theme(colors.white);
  }

  /** Headers have different height on free/elite. Elite can even have non-sticky header **/
  --fv-header-height: 0px;
  .is-header-sticky {
    --fv-header-height: 126px;
  }
}
